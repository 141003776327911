import {
  Box,
  Button,
  Divider,
  Fade,
  Heading,
  HStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  Portal,
  Text,
  VStack,
} from '@chakra-ui/react';

import { usePrevious } from '@gamma/hooks';
import { graphqlErrorRedirects, ROUTES } from '@gamma/investigator/constants';
import {
  DetectionAlertItem,
  EntityInformationContext,
  EntityLogscaleData,
} from '@gamma/investigator/context';
import { useDateRangeQueryString } from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import {
  DetectionSummary,
  useSimpleSearchQuery,
} from '@gamma/investigator/queries';
import { RouterLink } from '@gamma/navigation';
import { GraphQLReqStatus } from '@gamma/progress';
import { debounce } from 'lodash';
import moment from 'moment';
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { SkeletonLoader } from '../ChatGPT';
import { SeverityScore } from '../SeverityScore';
import { EntityInformationActions, EntityPopoverButton } from './Components';
import { EntityLogscaleDetails } from './Components/EntityLogscaleDetails/EntityLogscaleDetails';
import { useActiveEntityStates, useGetEntityInformation } from './hooks';

const { controls } = i18n.pages.detections;

interface EntityInformationTriggerProps {
  isStandalone?: boolean;
  conditionallySuppress?: boolean;
  detectionSummary: DetectionSummary;
}

export const EntityInformationTrigger = memo(
  ({
    isStandalone,
    detectionSummary,
    conditionallySuppress,
  }: EntityInformationTriggerProps) => {
    const {
      entity_id,
      entity_name,
      entity_type,
      entity_category,
      tenant_entity,
      tenant_info,
      last_seen,
    } = detectionSummary || {};

    const { tenant_id } = tenant_info || {};

    const { start, end } = useDateRangeQueryString();

    const [logscaleRespData, setLogscaleRespData] =
      useState<EntityLogscaleData | null>(null);

    const { malicious, published, internal, external } =
      i18n.pages.entityDetails;

    const {
      onIsolateOpen,
      onSuppressOpen,
      onEntityModalOpen,
      isEntityModalOpen,
      activeEntity,
      setActiveEntity,
      hoveredElement,
      setHoveredElement,
    } = useContext(EntityInformationContext);

    const {
      getEdrData,
      getEdrDataLoading,
      getEntityDetectionTimelinePaginated,
      getEntityDetectionTimelinePaginatedError,
      getEntityDetectionTimelinePaginatedLoading,
      queryAlertMetadataExcludedEntitiesPaginatedLoading,
    } = useGetEntityInformation({
      entityId: entity_id,
      tenantId: tenant_id,
    });

    useActiveEntityStates();

    const [contextMenuOpen, setContextMenuOpen] = useState<boolean>();
    const { entityName, entityCategory, detectionDataList } =
      activeEntity || {};

    const queryStringResponse = `#path=conn
            |id.resp_h=${entity_name}
            |ioc:lookup(field=[id.resp_h], type="ip_address", strict=false, confidenceThreshold="low", include=["published_date", "malicious_confidence"])
            |asn(field=id.resp_h)
            |ipLocation(field=id.resp_h, as=resp_geo)
            |table([id.resp_h.asn, id.resp_h.org, resp_geo.city, resp_geo.state, resp_cc, resp_geo.country, service, ioc[0].published_date, ioc[0].malicious_confidence, local_resp], limit=1)\n`;
    const queryStringOriginator = `#path=conn
            |id.orig_h=${entity_name}
            |resp_bytes>0 OR history=/[had]/
            |groupBy(service)
            |table([service], limit=1)`;

    const [simpleSearchQueryResponse, { loading: isSimpleSearchRespLoading }] =
      useSimpleSearchQuery({
        variables: {
          clientSessionId: localStorage.getItem('session_id_token'),
          queryString: queryStringResponse,
          end: end.toString(),
          start: start.toString(),
          tenantsForQuery: [tenant_id],
        },
        onCompleted: (data) => {
          if (data.simpleSearchQuery?.events[0]) {
            const parsedData = JSON.parse(data.simpleSearchQuery?.events[0]);
            setLogscaleRespData(parsedData);
            if (entity_id === activeEntity?.entityId) {
              setActiveEntity?.((prevState) => ({
                ...prevState,
                logscaleRespData: parsedData,
              }));
            }
          }
        },
      });
    const [
      simpleSearchQueryOriginator,
      { loading: isSimpleSearchOrigLoading },
    ] = useSimpleSearchQuery({
      variables: {
        clientSessionId: localStorage.getItem('session_id_token'),
        queryString: queryStringOriginator,
        end: end.toString(),
        start: start.toString(),
        tenantsForQuery: [tenant_id],
      },
      onCompleted: (data) => {
        if (data.simpleSearchQuery?.events[0]) {
          const parsedData = JSON.parse(data.simpleSearchQuery?.events[0]);
          if (entity_id === activeEntity?.entityId) {
            setActiveEntity?.((prevState) => ({
              ...prevState,
              logscaleOrigData: parsedData,
            }));
          }
        }
      },
    });

    const detectionCount = useMemo(
      () =>
        activeEntity?.detectionData?.total_items
          ? activeEntity?.detectionData?.total_items
          : 0,
      [activeEntity?.detectionData?.total_items],
    );

    useEffect(() => {
      setActiveEntity?.((prevState) => ({
        ...prevState,
        showSuppressEntity: conditionallySuppress
          ? detectionCount === 1 && entityCategory === 'source'
            ? true
            : false
          : entityCategory === 'source'
            ? true
            : false,
      }));
    }, [
      conditionallySuppress,
      detectionCount,
      entityCategory,
      setActiveEntity,
    ]);

    const prevActiveEntity = usePrevious(activeEntity);

    useEffect(() => {
      setActiveEntity?.((prevState) => ({
        ...prevState,
        isLoading:
          getEdrDataLoading ||
          getEntityDetectionTimelinePaginatedLoading ||
          queryAlertMetadataExcludedEntitiesPaginatedLoading,
      }));
    }, [
      setActiveEntity,
      getEdrDataLoading,
      getEntityDetectionTimelinePaginatedLoading,
      queryAlertMetadataExcludedEntitiesPaginatedLoading,
    ]);

    useEffect(() => {
      setActiveEntity?.((prevState) => ({
        ...prevState,
        isSimpleSearchOrigLoading,
      }));
    }, [setActiveEntity, isSimpleSearchOrigLoading]);

    useEffect(() => {
      setActiveEntity?.((prevState) => ({
        ...prevState,
        isSimpleSearchRespLoading,
      }));
    }, [setActiveEntity, isSimpleSearchRespLoading]);

    const debounceLogScale = useRef(
      debounce(() => {
        simpleSearchQueryResponse();
        simpleSearchQueryOriginator();
      }, 300),
    ).current;

    const handleEntityMouseEnter = useCallback(
      async (event: React.MouseEvent<HTMLDivElement>) => {
        setContextMenuOpen(false);
        if (entity_id !== prevActiveEntity?.entityId) {
          setActiveEntity?.(undefined);
        }
        await getEntityDetectionTimelinePaginated();
        setActiveEntity?.((prevState) => ({
          ...prevState,
          end,
          start,
          entityId: entity_id,
          entityName: entity_name,
          entityType: entity_type,
          entityCategory: entity_category,
          lastSeen: last_seen,
          tenantInfo: tenant_info,
          tenantEntity: tenant_entity,
        }));
        setHoveredElement?.(event.target as HTMLDivElement);
      },
      [
        entity_name,
        entity_id,
        prevActiveEntity?.entityId,
        getEntityDetectionTimelinePaginated,
        setActiveEntity,
        setHoveredElement,
        end,
        start,
        entity_type,
        entity_category,
        last_seen,
        tenant_info,
        tenant_entity,
      ],
    );

    const stackRef = useRef<HTMLDivElement>(null);

    const isHoveredEntity = useMemo(
      () =>
        hoveredElement &&
        entity_id === activeEntity?.entityId &&
        hoveredElement.parentElement?.parentElement === stackRef.current,
      [activeEntity?.entityId, entity_id, hoveredElement],
    );

    useEffect(() => {
      if (isHoveredEntity || entity_id === activeEntity?.entityId) {
        debounceLogScale();
      }
    }, [isHoveredEntity, debounceLogScale, entity_id, activeEntity?.entityId]);

    const handleEntityMouseLeave = useCallback(() => {
      setHoveredElement?.(undefined);
      if (!isEntityModalOpen) {
        debounceLogScale.cancel();
      }
    }, [debounceLogScale, isEntityModalOpen, setHoveredElement]);

    const handleEntityClick = useCallback(async () => {
      onEntityModalOpen?.();
      setContextMenuOpen?.(false);
    }, [onEntityModalOpen]);

    useEffect(() => {
      if (activeEntity?.entityId !== prevActiveEntity?.entityId) {
        getEdrData();
      }
    }, [getEdrData, prevActiveEntity, activeEntity?.entityId]);

    const detectionsPivotParams = useMemo(
      () =>
        `start=${start}&end=${end}&${
          entityCategory ?? 'source'
        }_name=${entityName}`,
      [end, entityCategory, entityName, start],
    );

    const {
      'ioc[0].published_date': iocPublishedDate,
      'ioc[0].malicious_confidence': iocMaliciousConfidence,
      local_resp,
    } = logscaleRespData || {};

    const entityEdrIsolated = useMemo(() => {
      return activeEntity?.entityIsIsolated !== undefined
        ? activeEntity?.entityIsIsolated
        : activeEntity?.entityEdrStatus?.entity_edr_status === 'isolated' ||
            activeEntity?.entityEdrStatus?.entity_edr_status ===
              'isolation_pending';
    }, [
      activeEntity?.entityIsIsolated,
      activeEntity?.entityEdrStatus?.entity_edr_status,
    ]);

    return (
      <VStack
        ref={stackRef}
        onMouseEnter={handleEntityMouseEnter}
        onMouseLeave={handleEntityMouseLeave}
      >
        <Popover
          isLazy
          offset={[0, 0]}
          placement="bottom-start"
          isOpen={isHoveredEntity}
        >
          <EntityPopoverButton
            entityName={entity_name}
            isStandalone={isStandalone}
            entityCategory={entity_category}
            detectionCount={detectionCount}
            contextMenuOpen={contextMenuOpen}
            isHoveredEntity={isHoveredEntity}
            handleEntityClick={handleEntityClick}
            setContextMenuOpen={setContextMenuOpen}
          />
          <Portal>
            <PopoverContent border="1px solid" borderColor="layer.3">
              {isHoveredEntity && !contextMenuOpen && (
                <Fade in={isHoveredEntity}>
                  {activeEntity?.isLoading ||
                  activeEntity?.isSimpleSearchOrigLoading ||
                  activeEntity?.isSimpleSearchRespLoading ? (
                    <Box w="400px">
                      <PopoverHeader>
                        <Heading textStyle="body-md-bold">
                          <Text>{entity_name}</Text>
                          <SkeletonLoader rowCount={1} />
                        </Heading>
                      </PopoverHeader>
                      <PopoverBody>
                        <SkeletonLoader rowCount={5} />
                      </PopoverBody>
                    </Box>
                  ) : (
                    <Box w="400px">
                      <PopoverHeader>
                        <Heading textStyle="body-md-bold">
                          {entity_name}
                        </Heading>
                        <HStack>
                          {local_resp === 'false' ? (
                            <Text
                              bg="blue.800"
                              color="blue.200"
                              borderRadius="md"
                              paddingX={1}
                            >{`${external}`}</Text>
                          ) : (
                            <Text
                              bg="blue.800"
                              color="blue.200"
                              borderRadius="md"
                              paddingX={1}
                            >{`${internal}`}</Text>
                          )}
                          {iocMaliciousConfidence && (
                            <>
                              <Text
                                bg="red.800"
                                color="red.200"
                                borderRadius="md"
                              >
                                {`${malicious}`}
                              </Text>
                              <Text
                                bg="red.800"
                                color="red.200"
                                borderRadius="md"
                                textTransform="capitalize"
                              >
                                {`${iocMaliciousConfidence}`}
                              </Text>
                            </>
                          )}
                          {iocPublishedDate && (
                            <Text
                              bg="blue.800"
                              color="blue.200"
                              borderRadius="md"
                            >{`${published} ${moment(Number(iocPublishedDate)).format('MMMM D, YYYY')}`}</Text>
                          )}
                        </HStack>
                      </PopoverHeader>
                      {getEntityDetectionTimelinePaginatedError ? (
                        <PopoverBody>
                          <GraphQLReqStatus
                            loading={false}
                            extended={graphqlErrorRedirects}
                            error={getEntityDetectionTimelinePaginatedError}
                          />
                        </PopoverBody>
                      ) : (
                        <PopoverBody>
                          <VStack alignItems="start" divider={<Divider />}>
                            <EntityLogscaleDetails
                              displayCard={true}
                              showHeader={false}
                              activeEntity={activeEntity}
                            />
                            {detectionDataList &&
                              detectionDataList?.length > 0 &&
                              detectionDataList?.map(
                                (alert: DetectionAlertItem) => (
                                  <HStack w="376px" key={alert.alert_name}>
                                    <SeverityScore
                                      score={alert?.severity}
                                      isCustom={alert?.is_custom_severity}
                                    />
                                    <Button
                                      w="340px"
                                      variant="link"
                                      as={RouterLink}
                                      justifyContent="start"
                                      to={{
                                        pathname: ROUTES.detections,
                                        search: `${detectionsPivotParams}&alert_category=${alert?.alert_name}`,
                                      }}
                                    >
                                      <Text
                                        as="span"
                                        overflow="hidden"
                                        whiteSpace="nowrap"
                                        textOverflow="ellipsis"
                                      >
                                        {alert?.alert_name}
                                      </Text>
                                    </Button>
                                  </HStack>
                                ),
                              )}
                            {detectionCount > 3 && (
                              <Button
                                variant="link"
                                as={RouterLink}
                                to={{
                                  pathname: ROUTES.detections,
                                  search: detectionsPivotParams,
                                }}
                              >
                                {controls.viewAll}
                              </Button>
                            )}
                          </VStack>
                        </PopoverBody>
                      )}
                      <PopoverFooter border={0} alignItems="end">
                        <EntityInformationActions
                          end={end}
                          start={start}
                          entityName={activeEntity?.entityName}
                          entityCategory={activeEntity?.entityCategory}
                          onIsolateEntity={onIsolateOpen}
                          onSuppressEntity={onSuppressOpen}
                          showIsolateEntity={
                            detectionCount === 1 &&
                            activeEntity?.showIsolateEntity
                          }
                          entityEdrIsolated={
                            activeEntity?.entityIsIsolated !== undefined
                              ? activeEntity?.entityIsIsolated
                              : entityEdrIsolated
                          }
                          entityIsSuppressed={activeEntity?.entityIsSuppressed}
                        />
                      </PopoverFooter>
                    </Box>
                  )}
                </Fade>
              )}
            </PopoverContent>
          </Portal>
        </Popover>
      </VStack>
    );
  },
);
