import {
  gql,
  LazyQueryHookOptions,
  OperationVariables,
  useLazyQuery,
} from '@apollo/client';

import { IQueryDetectionsPaginated } from './types';

export const QUERY_DETECTIONS_PAGINATED = gql`
  query queryDetectionsPaginated(
    $query: String
    $offset: Int
    $size: Int
    $sort: [SortParameterInput]
    $dest_paginated_entity_input: PaginatedEntityInput
    $source_paginated_entity_input: PaginatedEntityInput
  ) {
    queryDetectionsPaginated(
      query: $query
      offset: $offset
      size: $size
      sort: $sort
    ) {
      detections {
        alert_entity {
          entity_id
          entity_name
          entity_type
          entity_category
        }
        alert_info {
          alert_name
          alert_type
          content_id
        }
        assignment_info {
          assigned_at
          assigned_to_username
          assigned_to_user_alias
        }
        created_timestamp
        detection_keys {
          tenant_detection
          tenant_detection_entity
          tenant_entity
        }
        detection_id
        detection_status
        detection_timestamp {
          end
          start
        }
        earliest_start_timestamp
        latest_start_timestamp
        rank {
          severity
          is_custom_severity
        }
        tenant
        tenant_info {
          tenant_id
          tenant_display_name
        }
        total_alert_count
        update_info {
          last_updated_by
          last_updated_timestamp
        }
        escalation_data {
          escalation_status
          escalated_by_user_alias
          escalated_timestamp
          escalation_service_name
        }
        paginated_destination_entities(
          paginated_entity_input: $dest_paginated_entity_input
        ) {
          offset
          size
          alert_entities {
            entity_id
            entity_name
            entity_type
            entity_category
          }
          total_items
          sort {
            sort_by
            sort_dir
          }
        }
        paginated_source_entities(
          paginated_entity_input: $source_paginated_entity_input
        ) {
          offset
          size
          alert_entities {
            entity_id
            entity_name
            entity_type
            entity_category
          }
          total_items
          sort {
            sort_by
            sort_dir
          }
        }
      }
      offset
      size
      total_items
    }
  }
`;

export const useQueryDetectionsPaginated = (
  options: LazyQueryHookOptions<IQueryDetectionsPaginated, OperationVariables>,
) => {
  return useLazyQuery<IQueryDetectionsPaginated>(
    QUERY_DETECTIONS_PAGINATED,
    options,
  );
};
