import { createContext, Dispatch, SetStateAction } from 'react';
import { EntityInstance } from './EntityInformationProvider';

export interface IEntityInformationContext {
  onIsolateOpen?: () => void;
  onSuppressOpen?: () => void;
  onEntityModalOpen?: () => void;
  isEntityModalOpen?: boolean;
  hoveredElement?: HTMLDivElement;
  setHoveredElement?: Dispatch<SetStateAction<HTMLDivElement | undefined>>;
  activeEntity?: EntityInstance;
  setActiveEntity?: Dispatch<SetStateAction<EntityInstance | undefined>>;
  isLoading?: boolean;
  isSimpleSearchLoading?: boolean;
}

export const EntityInformationContext =
  createContext<IEntityInformationContext>({
    onIsolateOpen: () => false,
    onSuppressOpen: () => false,
    onEntityModalOpen: () => false,
    isEntityModalOpen: false,
    hoveredElement: undefined,
    setHoveredElement: () => false,
    activeEntity: undefined,
    setActiveEntity: () => false,
    isLoading: true,
    isSimpleSearchLoading: true,
  });
