import {
  gql,
  LazyQueryHookOptions,
  OperationVariables,
  useLazyQuery,
} from '@apollo/client';

import { IGetEDRConfiguration } from './types';

export const LAZY_ANALYST_GET_EDR_CONFIGURATION = gql`
  query getEDRConfiguration(
    $provider_name: EDRConfigurationType!
    $tenant: String
  ) {
    getEDRConfiguration(provider_name: $provider_name, tenant: $tenant) {
      edr_action_enabled
      provider_name
      enabled
      polling_seconds
      ... on CrowdStrikeConfiguration {
        provider_name
        polling_seconds
        enabled
        edr_action_enabled
      }
    }
  }
`;

export const useLazyAnalystGetEDRConfiguration = (
  options: LazyQueryHookOptions<IGetEDRConfiguration, OperationVariables>,
) =>
  useLazyQuery<IGetEDRConfiguration>(
    LAZY_ANALYST_GET_EDR_CONFIGURATION,
    options,
  );
