import { HStack, Text } from '@chakra-ui/react';

export interface LocationTagProps {
  countryCode?: string;
  city?: string;
  country?: string;
}

export const LocationTag = ({
  countryCode,
  city,
  country,
}: LocationTagProps) => {
  const locationString = [city, country].filter((item) => item).join(', ');
  return countryCode ? (
    <HStack data-testid="location-tag">
      <span
        className={`fi fi-${countryCode.toLowerCase()}`}
        style={{ borderRadius: '2px' }}
      ></span>
      {(city || country) && <Text>{locationString}</Text>};
    </HStack>
  ) : null;
};
