import {
  gql,
  LazyQueryHookOptions,
  OperationVariables,
  useLazyQuery,
} from '@apollo/client';

import { IGetEDRPayload } from './types';

export const LAZY_GET_EDR_PAYLOAD = gql`
  query getEdrPayload(
    $alert_entity_info: AlertEntityInfoInput
    $as_of: Float
    $tenant: String
  ) {
    getEdrPayload(
      alert_entity_info: $alert_entity_info
      as_of: $as_of
      tenant: $tenant
    ) {
      ad_domain_name
      ad_organizational_unit_name
      cloud_account_id
      cloud_instance_id
      cloud_provider
      data_source
      device_id
      entity_id
      entity_type
      expire_at
      external_ips
      host_names
      last_updated_timestamp
      mac_addresses
      nic_history {
        as_of
        max_results
        nic_histories {
          data_source
          ip_address
          mac_address
          obsts
        }
        offset
        size
        total_items
        use_cache
      }
      obsts
      os
      platform
      platform_type
      sensor_version
      tenant
      user_history {
        as_of
        max_results
        offset
        size
        total_items
        use_cache
        user_histories {
          data_source
          obsts
          username
        }
      }
    }
  }
`;

export const useLazyGetEDRPayload = (
  options: LazyQueryHookOptions<IGetEDRPayload, OperationVariables>,
) => useLazyQuery<IGetEDRPayload>(LAZY_GET_EDR_PAYLOAD, options);
