import { useQuery } from '@apollo/client';
import {
  Box,
  Center,
  Flex,
  HStack,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { TimestampCell } from '@gamma/investigator/components';
import { i18n } from '@gamma/investigator/localization';
import {
  Detection,
  IQueryAlertsPaginated,
  QUERY_ALERTS_NOTICE_PAGINATED,
  QUERY_ALERTS_NOTICE_SUMMARY,
  SessionSummaryData,
} from '@gamma/investigator/queries';
import { AccordionPanel } from '@gamma/layout';
import { GraphQLReqStatus } from '@gamma/progress';
import {
  DetailsGrid,
  DetailsGridRow,
} from 'libs/investigator/pages/detections/src/lib/Components';
import moment from 'moment';
import { Dispatch, SetStateAction, useState } from 'react';
import { SanitizedNoticeAlert } from '../../../DetectionAlerts';
import { InvestigateLogsTableModal } from '../InvestigateLogsTableModal';
import { LogscaleDetails } from '../LogscaleDetails';
import { LogscaleMenuButton } from '../LogscaleMenuButton';
import { SessionSummary } from '../SessionSummary';

const { alerts, detection } = i18n.pages.detections;

interface AlertRowNoticeProps {
  item: SanitizedNoticeAlert;
  logScaleQuery?: string;
  showContent?: boolean;
}

interface AlertRowSplitNoticeProps {
  item: SanitizedNoticeAlert;
  loading: boolean | undefined;
  setDownloadUrl?: Dispatch<SetStateAction<string>>;
  getAppConfigurationsData: string | null;
  detectionData: Detection;
  isGPTPrivateDataEnabled: boolean;
  logScaleQuery?: string;
}

export const NoticeButtonsCol = ({
  item,
  showContent,
  logScaleQuery,
}: AlertRowNoticeProps) => {
  const {
    isOpen: investigateModalIsOpen,
    onOpen: investigateModalOnOpen,
    onClose: investigateModalOnClose,
  } = useDisclosure();
  const formattedTime = moment.unix(item.alert_timestamp?.observed).format();

  const { alert_info, alert_entity, alert_timestamp } = item;
  const start = moment(formattedTime).subtract(1, 'hours').unix();
  const end = moment(formattedTime).add(24, 'hours').unix();

  const searchQueryString = `?query=${
    logScaleQuery
      ? encodeURIComponent(logScaleQuery)
      : `uid%3D${item?.uid}%20OR%20%28%23path%3D${alert_info?.alert_type}%20AND%20%28id.orig_h%3D${alert_entity?.entity_name}%20or%20id.resp_h%3D${alert_entity?.entity_name}%20or%20src%3D${alert_entity?.entity_name}%29%20AND%20note%20%3D%20%22${alert_info?.alert_name}%22%29%0A%7C%20select%28%5B%23path%2C%20id.orig_h%2C%20id.resp_h%2C%20id.resp_p%2C%20local_orig%2C%20local_resp%2C%20service%2C%20orig_bytes%2C%20resp_bytes%2C%20note%2C%20msg%2C%20qtype_name%2C%20rcode_name%2C%20query%2C%20answers%2C%20filename%2C%20rx_host%2C%20mime_type%2C%20seen_bytes%2C%20md5%2C%20sha256%2C%20method%2C%20status_code%2C%20host%2C%20uri%2C%20user_agent%2C%20client_headers%2C%20server_name%2C%20subject%2C%20issuer%2C%20established%2C%20validation_status%2C%20cert_chain_fps%5D%29`
  }&live=false&humioStart=${
    alert_timestamp && (alert_timestamp.observed - 1200) * 1000
  }&humioEnd=${
    alert_timestamp && (alert_timestamp.observed + 1200) * 1000
  }&fullscreen=false&widgetType=table-view&showOnlyFirstLine=false&start=${start}&end=${end}`;

  return (
    <Flex flexGrow={1}>
      <LogscaleMenuButton
        searchQueryString={searchQueryString}
        investigateModalOnOpen={investigateModalOnOpen}
        showContent={showContent}
      />
      <InvestigateLogsTableModal
        isOpen={investigateModalIsOpen}
        onClose={investigateModalOnClose}
        onOpen={investigateModalOnOpen}
        alertsName={item?.alert_info?.alert_name}
        alertsUid={item?.uid}
        alertsTimestamp={formattedTime}
        alertsTenant={item?.tenant}
        logScaleQuery={logScaleQuery}
      />
    </Flex>
  );
};

export const AlertRowNotice = ({
  item,
  logScaleQuery,
}: AlertRowNoticeProps) => {
  return (
    <HStack spacing={4}>
      <VStack alignItems="start">
        <TimestampCell
          timestamp={item?.alert_timestamp?.observed}
          isFromNow={false}
        />
        <HStack color="text.200">
          <Text textStyle="body-md">{item?.alert_info?.alert_name}</Text>
          <Text textStyle="body-md">|</Text>
          <Text textStyle="body-md">{item?.alert_entity?.entity_name}</Text>
        </HStack>
      </VStack>
      <NoticeButtonsCol item={item} logScaleQuery={logScaleQuery} />
    </HStack>
  );
};

export const AlertRowSplitNotice = ({
  item,
  loading,
  setDownloadUrl,
  getAppConfigurationsData,
  detectionData,
  isGPTPrivateDataEnabled,
  logScaleQuery,
}: AlertRowSplitNoticeProps) => {
  const [sessionSummaryData, setSessionSummaryData] = useState<
    SessionSummaryData | undefined
  >();
  const time = moment.unix(item?.alert_timestamp?.observed);
  const formattedTime = time.format();
  const isLLMSummary = getAppConfigurationsData
    ? JSON.parse(getAppConfigurationsData).feature_flags.llm_summary
    : false;

  const query =
    isLLMSummary && isGPTPrivateDataEnabled
      ? QUERY_ALERTS_NOTICE_SUMMARY
      : QUERY_ALERTS_NOTICE_PAGINATED;

  if (loading) {
    return (
      <Center w="100%">
        <GraphQLReqStatus loading={loading} />
      </Center>
    );
  }

  const noFilterVariables = {
    query: `{"query": {"bool": {"must": [{"term": {"alert_info.content_id": "${
      detectionData.alert_info?.content_id
    }"}}, {"term": {"alert_entity.entity_id": "${
      detectionData.alert_entity?.entity_id
    }"}}, {"range": {"alert_timestamp.end": {"gte": ${
      item.alert_timestamp.observed
    }}}}, {"range": {"alert_timestamp.start": {"lte": ${
      item.alert_timestamp.observed
    }}}}${detectionData.tenant_info?.tenant_id ? ',' : ''}${
      detectionData?.tenant_info?.tenant_id
        ? `{"terms":{"tenant":["${detectionData.tenant_info?.tenant_id}"]}}`
        : ''
    }]}}}`,
    log_search_client_session_id: localStorage.getItem('session_id_token'),
  };

  const { loading: sessionSummaryLoading } = useQuery<IQueryAlertsPaginated>(
    query,
    {
      skip:
        !detectionData ||
        !detectionData?.tenant_info?.tenant_id ||
        !detectionData?.alert_entity?.entity_id,
      variables: noFilterVariables,
      onCompleted: (data) => {
        const sessionSummary =
          data?.queryAlertsPaginated?.alerts?.[0]?.session_summary;
        setSessionSummaryData(sessionSummary);
      },
      onError: (error) => {
        console.log(error);
      },
    },
  );

  return (
    <VStack mb={2} w="100%" alignItems="start">
      <Box w="100%">
        <AccordionPanel
          title={detection.alertDetails}
          data-testid="notice-alerts-wrapper"
        >
          <DetailsGrid>
            <DetailsGridRow title={alerts.name}>
              <Text overflowWrap="anywhere">
                {item?.alert_info?.alert_name}
              </Text>
            </DetailsGridRow>
            <DetailsGridRow title={alerts.timestamp}>
              <Text>{formattedTime}</Text>
            </DetailsGridRow>
            <DetailsGridRow title={alerts.uid}>
              <Text>{item?.uid}</Text>
            </DetailsGridRow>
            <DetailsGridRow title={alerts.sourceIp}>
              <VStack alignItems="start" spacing={1}>
                {item?.source_entities &&
                  item.source_entities.length > 0 &&
                  item.source_entities.map((sourceIp) => (
                    <Text>{sourceIp?.entity_name}</Text>
                  ))}
              </VStack>
            </DetailsGridRow>
            <DetailsGridRow title={alerts.destinationIp}>
              <VStack alignItems="start" spacing={1}>
                {item?.destination_entities &&
                  item.destination_entities.length > 0 &&
                  item.destination_entities.map((destinationIp) => (
                    <Text>{destinationIp?.entity_name}</Text>
                  ))}
              </VStack>
            </DetailsGridRow>
            <DetailsGridRow title={alerts.type}>
              <Text>{item?.alert_info?.alert_type}</Text>
            </DetailsGridRow>
          </DetailsGrid>
          {isLLMSummary && isGPTPrivateDataEnabled && (
            <SessionSummary
              sessionSummaryData={sessionSummaryData}
              loading={sessionSummaryLoading}
            />
          )}
        </AccordionPanel>
      </Box>
      <LogscaleDetails
        alertsUid={item?.uid}
        alertsName={item?.alert_info?.alert_name}
        alertsTimestamp={formattedTime}
        setDownloadUrl={setDownloadUrl}
        alertsTenant={item?.tenant}
        logScaleQuery={logScaleQuery}
      />
    </VStack>
  );
};
