import {
  gql,
  LazyQueryHookOptions,
  OperationVariables,
  useLazyQuery,
} from '@apollo/client';

import { IGetEntityEdrStatus } from './types';

export const LAZY_GET_ENTITY_EDR_STATUS = gql`
  query getEntityEdrStatus($entity_id: String!, $tenant: String) {
    getEntityEdrStatus(entity_id: $entity_id, tenant: $tenant) {
      tenant
      entity_edr_status
      updated_timestamp
    }
  }
`;

export const useLazyGetEntityEdrStatus = (
  options: LazyQueryHookOptions<IGetEntityEdrStatus, OperationVariables>,
) => useLazyQuery<IGetEntityEdrStatus>(LAZY_GET_ENTITY_EDR_STATUS, options);
