import {
  gql,
  OperationVariables,
  QueryHookOptions,
  useLazyQuery,
} from '@apollo/client';

import { IsimpleSearchQuery } from './types';

export const SIMPLE_SEARCH_QUERY = gql`
  query simpleSearchQuery(
    $clientSessionId: String!
    $queryString: String!
    $end: String!
    $ingestEnd: String
    $ingestStart: String
    $languageVersion: String
    $size: Int
    $start: String!
    $timeZoneOffsetMinutes: Int
    $tenantsForQuery: [String]
  ) {
    simpleSearchQuery(
      clientSessionId: $clientSessionId
      queryString: $queryString
      end: $end
      ingestEnd: $ingestEnd
      ingestStart: $ingestStart
      languageVersion: $languageVersion
      start: $start
      size: $size
      timeZoneOffsetMinutes: $timeZoneOffsetMinutes
      tenantsForQuery: $tenantsForQuery
    ) {
      events
      total_items
      size
    }
  }
`;

export const useSimpleSearchQuery = (
  options?: QueryHookOptions<IsimpleSearchQuery, OperationVariables>,
) => {
  return useLazyQuery<IsimpleSearchQuery>(SIMPLE_SEARCH_QUERY, options);
};
