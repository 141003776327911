import { EntityInstance } from '@gamma/investigator/context';
import { useQueryAlertMetadataExcludedEntitiesPaginated } from '@gamma/investigator/queries';
import { Dispatch, SetStateAction } from 'react';

interface useQueryExcludedEntitiesProps {
  variables:
    | {
        query: string;
        offset: number;
        size: number;
        sort: { sort_by: string; sort_dir: string }[];
      }
    | undefined;
  setActiveEntity?: Dispatch<SetStateAction<EntityInstance | undefined>>;
}

export const useQueryExcludedEntities = ({
  variables,
  setActiveEntity,
}: useQueryExcludedEntitiesProps) => {
  return useQueryAlertMetadataExcludedEntitiesPaginated({
    skip: !variables,
    variables,
    onCompleted: ({ queryAlertMetadataExcludedEntitiesPaginated }) => {
      const excludedEntities =
        queryAlertMetadataExcludedEntitiesPaginated?.excluded_entities || [];
      const entityIsSuppressed = excludedEntities.length > 0;
      setActiveEntity?.((prevState) => ({
        ...prevState,
        excludeEntitiesData: excludedEntities,
        entityIsSuppressed,
      }));
    },
    onError: ({ extraInfo }) => {
      setActiveEntity?.((prevState) => ({
        ...prevState,
        excludeEntitiesError: extraInfo,
      }));
    },
  });
};
