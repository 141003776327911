import { EntityInstance } from '@gamma/investigator/context';
import {
  useGetEDRPayload,
  useGetEntityEdrStatus,
  useLazyAnalystGetEDRConfiguration,
} from '@gamma/investigator/queries';
import { Dispatch, SetStateAction } from 'react';

interface useLazyGetEdrDataProps {
  tenant?: string;
  activeEntity?: EntityInstance;
  setActiveEntity?: Dispatch<SetStateAction<EntityInstance | undefined>>;
}

export const useLazyGetEdrData = ({
  tenant,
  activeEntity,
  setActiveEntity,
}: useLazyGetEdrDataProps) => {
  const [
    getEDRConfiguration,
    { loading: getEDRConfigurationLoading, data: getEDRConfigurationData },
  ] = useLazyAnalystGetEDRConfiguration({
    variables: { tenant, provider_name: 'crowdstrike' },
    onCompleted: ({ getEDRConfiguration }) => {
      setActiveEntity?.((prevState) => ({
        ...prevState,
        edrConfigData: getEDRConfiguration,
      }));
    },
  });

  const { loading: getEDRPayloadLoading, data: getEDRPayloadData } =
    useGetEDRPayload({
      skip:
        !activeEntity?.entityName ||
        !activeEntity?.entityType ||
        !activeEntity?.lastSeen,
      variables: {
        tenant,
        alert_entity_info: {
          entity_name: activeEntity?.entityName,
          entity_type: activeEntity?.entityType,
        },
        as_of: activeEntity?.lastSeen,
      },
      onCompleted: ({ getEdrPayload }) => {
        setActiveEntity?.((prevState) => ({
          ...prevState,
          edrPayload: getEdrPayload,
        }));
      },
    });

  const { loading: getEntityEdrStatusLoading, data: getEntityEdrStatusData } =
    useGetEntityEdrStatus({
      fetchPolicy: 'network-only',
      skip: !tenant || !activeEntity?.edrPayload?.entity_id,
      variables: {
        tenant,
        entity_id: activeEntity?.edrPayload?.entity_id,
      },
      onCompleted: ({ getEntityEdrStatus }) => {
        const { entity_edr_status, tenant, updated_timestamp } =
          getEntityEdrStatus;
        setActiveEntity?.((prevState) => ({
          ...prevState,
          entityIsIsolated:
            entity_edr_status === 'isolated' ||
            entity_edr_status === 'isolation_pending',
          entityEdrStatus: {
            tenant,
            entity_edr_status,
            updated_timestamp,
          },
        }));
      },
    });

  const loading =
    getEDRPayloadLoading ||
    getEntityEdrStatusLoading ||
    getEDRConfigurationLoading;

  return {
    loading,
    getEDRPayloadData,
    getEntityEdrStatusData,
    getEDRConfiguration,
    getEDRConfigurationData,
  };
};
