export const formatJSONPreserveOrder = (data = '{}'): Map<string, string> => {
  const dataMap = new Map();
  const parsedData = JSON.parse(data);
  JSON.parse(data, (key) => {
    const parsedValue = parsedData[key];
    if (!key || parsedValue === undefined) return;
    typeof parsedValue !== 'string'
      ? dataMap.set(key, JSON.stringify(parsedValue))
      : dataMap.set(key, parsedValue);
  });
  return dataMap;
};
