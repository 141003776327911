import {
  gql,
  LazyQueryHookOptions,
  OperationVariables,
  useLazyQuery,
} from '@apollo/client';

import { EntityDetectionTimeline } from './types';

interface IGetEntityDetectionTimelinePaginated {
  getEntityDetectionTimelinePaginated: EntityDetectionTimeline;
}

export const LAZY_GET_ENTITY_DETECTION_TIMELINE_PAGINATED = gql`
  query getEntityDetectionTimelinePaginated(
    $start: Float!
    $end: Float!
    $size: Int
    $must_conds: [String]
    $tenant_id: String
    $entity_id: String
    $tenant_entity: String
    $sort: [SortParameterInput]
  ) {
    getEntityDetectionTimelinePaginated(
      start: $start
      end: $end
      size: $size
      must_conds: $must_conds
      tenant_id: $tenant_id
      entity_id: $entity_id
      tenant_entity: $tenant_entity
      sort: $sort
    ) {
      entity_id
      entity_name
      entity_type
      is_truncated
      offset
      severity
      size
      tenant
      tenant_entity
      tenant_info {
        tenant_id
        tenant_display_name
      }
      total_detections
      total_grouped_entities
      total_items
      data {
        alert_name
        alert_type
        content_id
        entity_id
        entity_name
        entity_type
        first_seen
        last_seen
        severity
        tenant
        tenant_detection
        tenant_entity
        tenant_info {
          tenant_id
          tenant_display_name
        }
        total_detections
        is_custom_severity
      }
    }
  }
`;

export const useLazyGetEntityDetectionTimelinePaginated = (
  options: LazyQueryHookOptions<
    IGetEntityDetectionTimelinePaginated,
    OperationVariables
  >,
) =>
  useLazyQuery<IGetEntityDetectionTimelinePaginated>(
    LAZY_GET_ENTITY_DETECTION_TIMELINE_PAGINATED,
    options,
  );
