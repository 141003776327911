import { Box, Center, HStack, Text, Wrap, WrapItem } from '@chakra-ui/react';
import { MuiIcon } from '@gamma/icons';
import { EntityInstance } from '@gamma/investigator/context';
import { i18n } from '@gamma/investigator/localization';
import { DetailsGridRow } from '@gamma/investigator/pages/detections';
import { GraphQLReqStatus } from '@gamma/progress';
import { memo } from 'react';
import { LocationTag } from '../../../LocationTag';

export interface EntityLogscaleDetailsProps {
  tenantId?: string;
  entityName?: string;
  showHeader?: boolean;
  displayCard?: boolean;
  activeEntity?: EntityInstance;
}

const { networkInformation, origin, asn, clientProtocols, serverProtocols } =
  i18n.pages.entityDetails;

export const EntityLogscaleDetails = memo(
  ({
    activeEntity,
    showHeader = true,
    displayCard = false,
  }: EntityLogscaleDetailsProps) => {
    const {
      logscaleRespData,
      logscaleOrigData,
      isSimpleSearchRespLoading,
      isSimpleSearchOrigLoading,
    } = activeEntity || {};

    const {
      resp_cc: countryCode,
      'resp_geo.country': country,
      'resp_geo.city': city,
      service: serviceResp,
      'id.resp_h.asn': asnValue,
      'id.resp_h.org': asnOrg,
    } = logscaleRespData || {};

    const { service: serviceOrig } = logscaleOrigData || {};
    const asnData = [asnValue, asnOrg].filter((data) => data);
    const detailsAlignment = displayCard ? 'column' : 'row';
    const isLoading = Boolean(
      isSimpleSearchRespLoading || isSimpleSearchOrigLoading,
    );

    return isLoading ? (
      <Center w="100%">
        <GraphQLReqStatus loading={isLoading} />
      </Center>
    ) : (
      <>
        {showHeader && (
          <HStack marginBottom={2}>
            <MuiIcon>settings_ethernet</MuiIcon>
            <Text textStyle="h5">{networkInformation}</Text>
          </HStack>
        )}
        <Wrap spacing={2} maxWidth="400px" margin={1}>
          <WrapItem display="flex" flexDirection={detailsAlignment}>
            <DetailsGridRow
              title={
                <Text minW="130" color={'gray.200'}>
                  {origin}
                </Text>
              }
            >
              {countryCode ? (
                <Box minW="130">
                  <LocationTag
                    countryCode={countryCode}
                    country={country}
                    city={city}
                  />
                </Box>
              ) : (
                '---'
              )}
            </DetailsGridRow>
          </WrapItem>
          <WrapItem display="flex" flexDirection={detailsAlignment}>
            <DetailsGridRow
              title={
                <Text minW="130" color={'gray.200'}>
                  {asn}
                </Text>
              }
            >
              <Text minW="130" maxW="160">
                {asnData.join(', ') || '---'}
              </Text>
            </DetailsGridRow>
          </WrapItem>
          <WrapItem display="flex" flexDirection={detailsAlignment}>
            <DetailsGridRow
              title={
                <Text minW="130" color={'gray.200'}>
                  {clientProtocols}
                </Text>
              }
            >
              <Text textTransform="uppercase" minW="130">
                {serviceOrig || '---'}
              </Text>
            </DetailsGridRow>
          </WrapItem>
          <WrapItem display="flex" flexDirection={detailsAlignment}>
            <DetailsGridRow
              title={
                <Text minW="130" color={'gray.200'}>
                  {serverProtocols}
                </Text>
              }
            >
              <Text minW="130" textTransform="uppercase">
                {serviceResp || '---'}
              </Text>
            </DetailsGridRow>
          </WrapItem>
        </Wrap>
      </>
    );
  },
);
