import { DataTableProps } from '@gamma/data-table';
import { ReactNode, useState } from 'react';
import { ColumnInstance } from 'react-table';
import { DataTableContext } from '../../context';

export type DataTableContextProviderProps<DataType extends {}> = {
  children: ReactNode;
  data: DataTableProps<DataType>['data'];
};

export const DataTableContextProvider = <DataType extends {}>({
  children,
  data,
}: DataTableContextProviderProps<DataType>) => {
  const totalLength = useState<number>(data?.length || 0);
  const curPageLength = useState<number>(0);
  const filteredLength = useState<number>(data?.length || 0);
  const columns = useState<Array<ColumnInstance<DataType>>>([]);

  return (
    <DataTableContext.Provider
      value={{
        totalLength,
        curPageLength,
        filteredLength,
        columns,
      }}
    >
      {children}
    </DataTableContext.Provider>
  );
};
