import { Button, ButtonGroup, Text, useDisclosure } from '@chakra-ui/react';
import { DataTable } from '@gamma/data-table';
import { useQueryWithRetry } from '@gamma/investigator/hooks';
import {
  useCreateQueryJob,
  usePollQueryJob,
} from '@gamma/investigator/queries';
import { Modal } from '@gamma/overlay';
import { GraphQLReqStatus } from '@gamma/progress';
import { IPollQueryJob } from 'libs/investigator/queries/src/lib/humio/types';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Column } from 'react-table';
import { LogscaleDetailsModal } from '../LogscaleDetailsModal';

export interface InvestigateLogsTableProps {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  alertsName: string;
  alertsUid?: string;
  alertsTimestamp: string;
  alertsTenant?: string;
  logScaleQuery?: string;
}

export interface LogDetails {
  ['#path']: string;
  ['id.orig_h']: string;
  ['id.resp_p']: string;
  local_orig: string;
  local_resp: string;
}

export const InvestigateLogsTableModal = ({
  isOpen,
  onClose,
  onOpen,
  alertsName,
  alertsUid,
  alertsTimestamp,
  alertsTenant,
  logScaleQuery,
}: InvestigateLogsTableProps) => {
  const [investigateLogsTableData, setInvestigateLogsTableData] = useState<
    any[]
  >([]);
  const [activeRow, setActiveRow] = useState<object>({});
  const [logscaleType, setLogscaleType] = useState<string>('');
  const [logDetailsQueryJobId, setLogDetailsQueryJobId] = useState<string>();

  const {
    isOpen: logScaleDetailsIsOpen,
    onOpen: logScaleDetailsOnOpen,
    onClose: logScaleDetailsOnClose,
  } = useDisclosure();

  const startTime = moment(alertsTimestamp).subtract(20, 'minutes').unix();

  const endTime = moment(alertsTimestamp).add(20, 'minutes').unix();

  const prevToken = useRef(localStorage.getItem('session_id_token'));

  const [
    logDetailsCreateQueryJob,
    { loading: logDetailsCreateQueryJobLoading },
  ] = useCreateQueryJob({
    variables: {
      clientSessionId: localStorage.getItem('session_id_token'),
      queryInputJob: {
        queryStringWithoutSortSize: alertsUid
          ? `uid="${alertsUid}"`
          : logScaleQuery,
        start: startTime.toString(),
        end: endTime.toString(),
        tenantsForQuery: [alertsTenant],
      },
      size: 400,
      sort: [
        {
          sort_by: '_write_ts',
          sort_dir: 'asc',
        },
      ],
    },
    onCompleted: (data) => {
      if (data?.createQueryJob?.query_job_id) {
        setLogDetailsQueryJobId(data.createQueryJob.query_job_id);
      }
    },
  });

  const { loading: logDetailsLoading } = useQueryWithRetry<IPollQueryJob>({
    queryHook: usePollQueryJob,
    variables: {
      clientSessionId: localStorage.getItem('session_id_token'),
      queryJobId: logDetailsQueryJobId,
    },
    skip: !logDetailsQueryJobId,
    onCompleted(data) {
      const logsData = data?.pollQueryJob?.events?.map((item) =>
        JSON.parse(item),
      );
      setInvestigateLogsTableData(logsData);
    },
    onError() {
      setInvestigateLogsTableData([]);
    },
  });

  useEffect(() => {
    setLogDetailsQueryJobId('');
  }, [alertsUid, alertsTimestamp]);

  useEffect(() => {
    if (isOpen && (alertsUid || logScaleQuery)) {
      if (
        !logDetailsQueryJobId ||
        prevToken.current !== localStorage.getItem('session_id_token')
      ) {
        logDetailsCreateQueryJob();
        prevToken.current = localStorage.getItem('session_id_token');
      }
    }
  }, [alertsUid, isOpen, localStorage.getItem('session_id_token')]);

  const dataTableColumns: Column<LogDetails>[] = useMemo(() => {
    return [
      {
        Header: '#path',
        accessor: '#path',
      },
      {
        Header: 'id.orig_h',
        accessor: (row) => row['id.orig_h'],
        Cell: (props: any) => <Text>{props?.value ?? '-'}</Text>,
      },
      {
        Header: 'id.resp_p',
        accessor: (row) => row['id.resp_p'],
        Cell: (props: any) => <Text>{props?.value ?? '-'}</Text>,
      },
      {
        Header: 'local_orig',
        accessor: 'local_orig',
        Cell: (props: any) => <Text>{props?.value ?? '-'}</Text>,
      },
      {
        Header: 'local_resp',
        accessor: 'local_resp',
        Cell: (props: any) => <Text>{props?.value ?? '-'}</Text>,
      },
    ];
  }, []);

  const handleRowClick = (e: any) => {
    setLogscaleType(e.original?.['#path']);
    setActiveRow(e.original);
    logScaleDetailsOnOpen();
    onClose();
  };

  return (
    <>
      <Modal
        size="xl"
        isOpen={isOpen}
        onClose={onClose}
        title={`${alertsName} | ${alertsTimestamp}`}
        body={
          logDetailsCreateQueryJobLoading || logDetailsLoading ? (
            <GraphQLReqStatus
              loading={logDetailsCreateQueryJobLoading || logDetailsLoading}
            />
          ) : (
            <DataTable
              data={investigateLogsTableData}
              columns={dataTableColumns}
              isLined={true}
              autoResetPage={false}
              autoResetSortBy={false}
              autoResetRowSelection={false}
              onRowClick={handleRowClick}
            />
          )
        }
        footer={
          <ButtonGroup>
            <Button variant="solid" colorScheme="gray" onClick={onClose}>
              Close
            </Button>
          </ButtonGroup>
        }
      />
      <LogscaleDetailsModal
        alertsName={alertsName}
        alertsTimestamp={alertsTimestamp}
        isModalOpen={logScaleDetailsIsOpen}
        onModalClose={logScaleDetailsOnClose}
        onOpen={onOpen}
        data={activeRow}
        title={`${logscaleType ? logscaleType.toUpperCase() : 'Log'} Details`}
        showBackToLogFlag={true}
      />
    </>
  );
};
