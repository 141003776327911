import { ApolloError } from '@apollo/client';
import {
  Box,
  Button,
  HStack,
  Link,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { SeverityScore } from '@gamma/investigator/components';
import { ROUTES } from '@gamma/investigator/constants';
import { AuthContext, SlideDrawerContext } from '@gamma/investigator/context';
import {
  useDateRangeQueryString,
  useManualPagination,
} from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import {
  AlertEntity,
  Detection,
  IGetAlertMetadata,
  IGetDetections,
  useGetDetections,
  useQueryAlertMetadataExcludedEntitiesPaginated,
} from '@gamma/investigator/queries';
import { stringifyAlertType } from '@gamma/investigator/utilities';
import { AccordionPanel, Panel, PanelHeader } from '@gamma/layout';
import { RouterLink } from '@gamma/navigation';
import { GraphQLReqStatus } from '@gamma/progress';
import DOMPurify from 'dompurify';
import moment from 'moment';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useOutletContext } from 'react-router-dom';

import { createPortal } from 'react-dom';
import {
  AddNoteContent,
  DetailsGridRow,
  DetectionAssignee,
  DetectionDrawer,
  DetectionDrawerActions,
  DetectionNotesDrawer,
  DetectionStatus,
  IGetDetectionVariables,
  MitreTechniquesGrid,
} from '../../Components';
import { formatSort } from '../../Detections';
import { IDetectionContext } from '../Detection';
import { DetectionEntities, GPTPrompts, RelatedDetections } from './Components';
import { Modal } from '@gamma/overlay';

const { systemSettingsAlertCatalog } = ROUTES;

const {
  correlationChart,
  detection: detectionText,
  detectionDetails,
} = i18n.pages.detections;
const { headings } = correlationChart;

const {
  assignee,
  alertCategory,
  significance,
  mitreTechniques,
  validation,
  nextSteps,
  description: localeDesc,
  status,
  type,
  severity,
  firstTimeSeen,
  lastTimeSeen,
  numberOfAlerts,
  notes,
  addNote,
} = detectionText;

interface DetectionDetailsProps {
  getAppConfigurationsData: string | null;
  getAppConfigurationsLoading: boolean;
  detailsProps?: {
    detectionData?: IGetDetections;
    detectionError?: ApolloError;
    detectionLoading?: boolean;
    metadataData?: IGetAlertMetadata;
    metadataError?: ApolloError;
    metadataLoading?: boolean;
    getDetectionVariables?: IGetDetectionVariables;
  };
}

function safelySetInnerHTML(html: string) {
  return {
    ['dangerouslySetInnerHTML']: {
      __html: DOMPurify.sanitize(html) ?? '',
    },
  };
}

export const DetectionDetails = ({
  detailsProps,
  getAppConfigurationsData,
  getAppConfigurationsLoading,
}: DetectionDetailsProps) => {
  const {
    detectionData,
    detectionError,
    detectionLoading,
    metadataData,
    metadataError,
    metadataLoading,
    edrConfigError,
    edrConfigLoading,
    getDetectionVariables,
  } = useOutletContext<IDetectionContext>() || {};

  const { user, userRole } = useContext(AuthContext);

  const {
    portalType,
    slideDrawerBody,
    slideDrawerTitle,
    slideDrawerActions,
    setIsSlideDrawerOpen,
    setSlideDrawerOnClose,
    setPortalType,
  } = useContext(SlideDrawerContext);

  const {
    isOpen: isAddNoteModalOpen,
    onOpen: addNoteModalOnOpen,
    onClose: addNoteModalOnClose,
  } = useDisclosure();

  const { start, end } = useDateRangeQueryString();

  const detectionDetailsProps = useMemo(() => {
    return detailsProps
      ? {
          ...detailsProps,
        }
      : {
          detectionData,
          detectionError,
          detectionLoading,
          metadataData,
          metadataError,
          metadataLoading,
          getDetectionVariables,
        };
  }, [
    detailsProps,
    detectionData,
    detectionError,
    detectionLoading,
    metadataData,
    metadataError,
    metadataLoading,
    getDetectionVariables,
  ]);

  const [suricataRuleText, setSuricataRuleText] = useState('');

  const detectionInfo =
    detectionDetailsProps?.detectionData?.getDetections?.detections[0];

  const [selectedDetectionId, setSelectedDetectionId] = useState<string>();

  const { loading: getDetectionsLoading, data } = useGetDetections({
    fetchPolicy: 'network-only',
    skip: !selectedDetectionId,
    variables: {
      detection_ids: [
        {
          detection_id: selectedDetectionId,
          tenant: user?.attributes['custom:tenant_id'],
        },
      ],
    },
  });

  const [selectedDetection, setSelectedDetection] = useState<
    Detection | undefined
  >();

  useEffect(() => {
    setSelectedDetection(data?.getDetections?.detections?.[0]);
  }, [data]);

  useEffect(() => {
    setSelectedDetectionId(detectionInfo?.detection_id);
  }, [detectionInfo]);

  useEffect(() => {
    setPortalType?.('detection');
  }, [setPortalType, selectedDetectionId]);

  const {
    rank,
    alert_info,
    alert_entity,
    assignment_info,
    detection_status,
    total_alert_count,
    earliest_start_timestamp,
    latest_start_timestamp,
  } = detectionInfo || {};

  const {
    description,
    content_doc,
    mitre_mappings: metadata_mitre_mappings,
  } = detectionDetailsProps?.metadataData?.getAlertMetadata?.metadata[0] || {};

  const [activeEntity, setActiveEntity] = useState<AlertEntity | null>(null);

  const { entity_name, entity_type } = activeEntity || alert_entity || {};

  const queryVariables = {
    query:
      entity_type === 'DOMAIN'
        ? `{"query": {"bool": {"must": [{"match": {"content_id": "${
            detectionInfo?.alert_info?.content_id
          }"}}, {"term": {"entity_name.keyword": "${entity_name}"}}, {"term": {"entity_type": "${entity_type}"}}${
            detectionInfo?.tenant_info?.tenant_id ? ',' : ''
          }${
            detectionInfo?.tenant_info?.tenant_id
              ? `{"terms":{"tenant":["${detectionInfo?.tenant_info?.tenant_id}"]}}`
              : ''
          }]}}}`
        : `{"query": {"bool": {"must": [{"match": {"content_id": "${
            detectionInfo?.alert_info?.content_id
          }"}}, {"term": {"entity_type": "${entity_type}"}}, {"bool": {"should": [{"term": {"entity_ip": "${entity_name}"}}, {"range": {"entity_ip_range": {"from": "${entity_name}", "to": "${entity_name}"}}}]}}${
            detectionInfo?.tenant_info?.tenant_id ? ',' : ''
          }${
            detectionInfo?.tenant_info?.tenant_id
              ? `{"terms":{"tenant":["${detectionInfo?.tenant_info?.tenant_id}"]}}`
              : ''
          }]}}}`,
    offset: 0,
    size: 10,
    sort: [
      {
        sort_by: 'entity_name.keyword',
        sort_dir: 'desc',
      },
    ],
  };

  const { data: excludeEntitiesData, error: excludeEntitiesError } =
    useQueryAlertMetadataExcludedEntitiesPaginated({
      skip:
        !entity_name ||
        !entity_type ||
        !detectionInfo?.tenant_info?.tenant_id ||
        !detectionInfo?.alert_info?.content_id,
      variables: queryVariables,
    });

  // set panel description
  useEffect(() => {
    if (detectionDetailsProps?.metadataLoading) {
      return;
    }

    const suricataRuleText =
      detectionDetailsProps?.metadataData?.getAlertMetadata?.metadata[0]
        ?.rule ?? '';

    setSuricataRuleText(suricataRuleText);
  }, [
    description,
    detectionDetailsProps?.metadataLoading,
    alert_info?.alert_type,
    detectionDetailsProps?.metadataData?.getAlertMetadata?.metadata,
  ]);

  const hasMetaContent = !!(description || content_doc?.significance);

  const isLoading =
    edrConfigLoading ||
    getAppConfigurationsLoading ||
    detectionDetailsProps?.metadataLoading ||
    detectionDetailsProps?.detectionLoading;

  const nextStepsRef = useRef<HTMLDivElement>(null);

  const validationRef = useRef<HTMLDivElement>(null);

  const [isExcluded, setIsExcluded] = useState<boolean>(false);

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const { getSortsForAPI } = useManualPagination<any>({
    // @ts-ignore
    sortBy: formatSort(queryVariables?.sortBy),
  });

  useEffect(() => {
    setSlideDrawerOnClose?.(() => () => {
      setActiveEntity(null);
      setSelectedDetection(undefined);
    });
  }, [setSlideDrawerOnClose, setActiveEntity, setSelectedDetection]);

  return isLoading || detectionDetailsProps?.metadataError ? (
    <GraphQLReqStatus
      loading={!!isLoading}
      error={
        detectionDetailsProps?.detectionError ||
        detectionDetailsProps?.metadataError ||
        edrConfigError
      }
    />
  ) : (
    <Box>
      <VStack spacing={4} data-testid="detection-details-wrapper">
        <Panel>
          <PanelHeader
            borderBottomWidth="1px"
            borderBottomColor={'border.layer.1'}
          >
            <Text textStyle="h5">{detectionDetails}</Text>
          </PanelHeader>
          <HStack>
            <VStack w="315px" alignItems="start">
              <Text textStyle="body-md" color="text.secondary">
                {status}
              </Text>
              <DetectionStatus
                refetchMain={true}
                detectionInfo={detectionInfo}
                detectionStatus={detection_status}
                getDetectionVariables={
                  detectionDetailsProps?.getDetectionVariables
                }
              />
            </VStack>
            <VStack w="315px" alignItems="start">
              <Text textStyle="body-md" color="text.secondary">
                {assignee}
              </Text>
              <DetectionAssignee
                refetchMain={true}
                detectionInfo={detectionInfo}
                assignmentInfo={assignment_info}
                getDetectionVariables={
                  detectionDetailsProps?.getDetectionVariables
                }
              />
            </VStack>
          </HStack>
        </Panel>
        <AccordionPanel
          w="100%"
          title="Alert Category"
          reduceMotion={true}
          storageKey="detection-details-alert-category"
        >
          <HStack gap={8} alignItems="start">
            <VStack w="70%" alignItems="start">
              {hasMetaContent &&
                (description ?? '')?.length > 0 &&
                alert_info?.alert_type !== 'suricata_corelight' && (
                  <DetailsGridRow
                    isDetailsPage
                    title={localeDesc}
                    data-testid="detection-description"
                  >
                    <Text>{description}</Text>
                  </DetailsGridRow>
                )}
              {hasMetaContent && content_doc?.significance && (
                <DetailsGridRow
                  isDetailsPage
                  title={significance}
                  data-testid="detection-significance"
                >
                  <Text as="div">{content_doc.significance}</Text>
                </DetailsGridRow>
              )}
              {isExpanded && hasMetaContent && content_doc?.validation && (
                <DetailsGridRow
                  isDetailsPage
                  title={validation}
                  data-testid="detection-content-doc-validation"
                >
                  <Box
                    ref={validationRef}
                    {...safelySetInnerHTML(content_doc?.validation)}
                    __css={{
                      ul: {
                        'margin-left': '16px',
                      },
                    }}
                  />
                </DetailsGridRow>
              )}
              {isExpanded && hasMetaContent && content_doc?.nextsteps && (
                <DetailsGridRow
                  isDetailsPage
                  title={nextSteps}
                  data-testid="detection-content-doc-next-steps"
                >
                  <Box
                    ref={nextStepsRef}
                    {...safelySetInnerHTML(content_doc?.nextsteps)}
                    __css={{
                      ul: {
                        'margin-left': '16px',
                      },
                    }}
                  />
                </DetailsGridRow>
              )}
              {alert_info?.alert_type !== 'custom_search_rule' && (
                <DetailsGridRow
                  isDetailsPage
                  title=""
                  data-testid="detection-ask-gpt"
                >
                  <GPTPrompts detailsProps={detailsProps} />
                </DetailsGridRow>
              )}
              {hasMetaContent &&
                (content_doc?.nextsteps || content_doc?.validation) && (
                  <Link
                    color="text.link"
                    onClick={() => setIsExpanded(!isExpanded)}
                  >
                    {!isExpanded ? 'See More' : 'See Less'}
                  </Link>
                )}
            </VStack>
            <VStack alignItems="start">
              {alert_info?.alert_name && (
                <DetailsGridRow
                  isDetailsPage
                  data-testid="detection-alert-name"
                  title={alertCategory}
                >
                  <Box
                    __css={{
                      wordWrap: 'anywhere',
                      _hover: { textDecoration: 'underline' },
                    }}
                  >
                    <RouterLink
                      color="text.link"
                      __css={{
                        wordWrap: 'anywhere',
                      }}
                      data-testid="pivot-detection-alert-catalog-link"
                      to={`${systemSettingsAlertCatalog(
                        `/${alert_info?.content_id}?start=${start}&end=${end}`,
                      )}`}
                    >
                      {alert_info?.alert_name}
                    </RouterLink>
                  </Box>
                </DetailsGridRow>
              )}
              {rank?.severity !== null && rank?.severity !== undefined && (
                <DetailsGridRow
                  isDetailsPage
                  data-testid="detection-alert-severity-score"
                  title={severity}
                >
                  <SeverityScore
                    score={rank.severity}
                    isCustom={rank?.is_custom_severity}
                  />
                </DetailsGridRow>
              )}
              {total_alert_count && (
                <DetailsGridRow
                  isDetailsPage
                  title={numberOfAlerts}
                  data-testid="detection-alert-count"
                >
                  <Text>{total_alert_count}</Text>
                </DetailsGridRow>
              )}
              {earliest_start_timestamp && (
                <DetailsGridRow
                  isDetailsPage
                  title={firstTimeSeen}
                  data-testid="detection-first-timestamp"
                >
                  <Text>
                    {moment
                      .unix(earliest_start_timestamp)
                      .format('MMMM Do, h:mma')}
                  </Text>
                </DetailsGridRow>
              )}
              {latest_start_timestamp && (
                <DetailsGridRow
                  isDetailsPage
                  title={lastTimeSeen}
                  data-testid="detection-last-timestamp"
                >
                  <Text>
                    {moment
                      .unix(latest_start_timestamp)
                      .format('MMMM Do, h:mma')}
                  </Text>
                </DetailsGridRow>
              )}
              {alert_info?.alert_type && (
                <DetailsGridRow
                  title={type}
                  isDetailsPage
                  data-testid="detection-type"
                >
                  <Text>{stringifyAlertType(alert_info?.alert_type)}</Text>
                </DetailsGridRow>
              )}
              {!!metadata_mitre_mappings?.tactics?.length && (
                <VStack alignItems="start">
                  <Text
                    color="text.secondary"
                    data-testid={'metadata_mitre_mappings-label'}
                  >
                    {mitreTechniques}
                  </Text>
                  <MitreTechniquesGrid
                    tactics={metadata_mitre_mappings?.tactics}
                  />
                </VStack>
              )}
            </VStack>
          </HStack>
        </AccordionPanel>
        <DetectionEntities
          activeEntity={activeEntity}
          detectionInfo={detectionInfo}
          queryVariables={queryVariables}
          setActiveEntity={setActiveEntity}
          excludeEntitiesError={excludeEntitiesError}
          excludeEntitiesData={
            excludeEntitiesData?.queryAlertMetadataExcludedEntitiesPaginated
              ?.excluded_entities
          }
        />
        {detectionInfo && (
          <AccordionPanel
            w="100%"
            reduceMotion={true}
            title={
              <HStack>
                <Text>{headings.main}</Text>
                <Text fontWeight="400" color="text.secondary">
                  {headings.thirtyDays}
                </Text>
              </HStack>
            }
            storageKey="detection-details-chart"
          >
            <RelatedDetections
              detection={detectionInfo}
              suricataRuleText={suricataRuleText}
              selectedDetection={selectedDetection}
              getDetectionsLoading={getDetectionsLoading}
              setIsSlideDrawerOpen={setIsSlideDrawerOpen}
              selectedDetectionId={selectedDetectionId}
              setSelectedDetectionId={setSelectedDetectionId}
              getAppConfigurationsData={getAppConfigurationsData}
            />
          </AccordionPanel>
        )}
      </VStack>
      {portalType === 'notes' &&
        slideDrawerBody &&
        createPortal(
          <DetectionNotesDrawer item={selectedDetection} />,
          slideDrawerBody,
        )}
      {portalType === 'notes' &&
        slideDrawerTitle &&
        createPortal(
          <>
            <Box>{notes}</Box>
            {userRole !== 'viewer' && (
              <Button
                variant="solid"
                colorScheme="gray"
                marginTop="1"
                onClick={addNoteModalOnOpen}
              >
                {addNote}
              </Button>
            )}
            <Modal
              size="sm"
              isOpen={isAddNoteModalOpen}
              onClose={addNoteModalOnClose}
              title={addNote}
              body={
                <AddNoteContent
                  onClose={addNoteModalOnClose}
                  detectionData={detectionInfo}
                  refetchMain={true}
                />
              }
            />
          </>,
          slideDrawerTitle,
        )}
      {portalType === 'detection' &&
        slideDrawerBody &&
        createPortal(
          selectedDetection && (
            <DetectionDrawer
              disableBorders={true}
              item={selectedDetection}
              queryDetectionsPaginatedVariables={{
                query: queryVariables.query,
                size: queryVariables.size,
                offset: queryVariables.offset,
                // @ts-ignore
                sort: getSortsForAPI(formatSort(queryVariables.sortBy)),
              }}
            />
          ),
          slideDrawerBody,
        )}
      {portalType === 'detection' &&
        slideDrawerActions &&
        createPortal(
          selectedDetection && queryVariables && (
            <Box w="100%" my={2}>
              <DetectionDrawerActions
                detection={selectedDetection}
                setIsExcluded={setIsExcluded}
                setIsSlideDrawerOpen={setIsSlideDrawerOpen}
                queryDetectionsPaginatedVariables={{
                  query: queryVariables.query,
                  size: queryVariables.size,
                  offset: queryVariables.offset,
                  // @ts-ignore
                  sort: getSortsForAPI(formatSort(queryVariables.sortBy)),
                }}
              />
            </Box>
          ),
          slideDrawerActions,
        )}
    </Box>
  );
};
