import {
  gql,
  LazyQueryHookOptions,
  OperationVariables,
  useLazyQuery,
} from '@apollo/client';

import { CustomSearchRuleAlert, IQueryAlertsPaginated } from './types';

export const QUERY_ALERTS_SEARCH_BASED_PAGINATED = gql`
  query queryAlertsSearchBasedPaginated(
    $query: String!
    $offset: Int
    $size: Int
    $sort: [SortParameterInput]
  ) {
    queryAlertsPaginated(
      query: $query
      offset: $offset
      size: $size
      sort: $sort
    ) {
      alerts {
        ... on CustomSearchRuleAlert {
          alert_entity {
            entity_id
            entity_name
            entity_type
          }
          alert_id
          alert_info {
            alert_name
            alert_type
            content_id
          }
          alert_keys {
            tenant_alert
            tenant_alert_entity
            tenant_entity
          }
          alert_timestamp {
            end
            observed
            start
            ttl
          }
          event_ids
          false_positive
          related_entities {
            entity_id
            entity_name
            entity_type
          }
          score
          severity
          tenant
          source_entities {
            entity_id
            entity_name
            entity_type
            entity_category
          }
          destination_entities {
            entity_id
            entity_name
            entity_type
            entity_category
          }
        }
      }
      offset
      size
      total_items
    }
  }
`;

export const useQueryAlertsSearchBasedPaginated = (
  options: LazyQueryHookOptions<
    IQueryAlertsPaginated<CustomSearchRuleAlert>,
    OperationVariables
  >,
) =>
  useLazyQuery<IQueryAlertsPaginated<CustomSearchRuleAlert>>(
    QUERY_ALERTS_SEARCH_BASED_PAGINATED,
    options,
  );
