import {
  gql,
  LazyQueryHookOptions,
  OperationVariables,
  useLazyQuery,
} from '@apollo/client';

import { IQueryAlertsPaginated, MlAlert } from './types';

export const QUERY_ALERTS_ML_PAGINATED = gql`
  query queryAlertsMLPaginated(
    $query: String!
    $offset: Int
    $size: Int
    $sort: [SortParameterInput]
  ) {
    queryAlertsPaginated(
      query: $query
      offset: $offset
      size: $size
      sort: $sort
    ) {
      alerts {
        ... on MlAlert {
          average_technique_score_map
          description
          alert_entity {
            entity_id
            entity_name
            entity_type
          }
          alert_id
          alert_info {
            alert_name
            alert_type
            content_id
          }
          alert_keys {
            tenant_alert
            tenant_alert_entity
            tenant_entity
          }
          alert_timestamp {
            end
            observed
            start
            ttl
          }
          event_ids
          false_positive
          mode
          models {
            features {
              actual
              contribution
              difference
              feature_name
            }
            model_alias
            model_name
            model_score
            predicted_tag_id
          }
          pipeline
          related_entities {
            entity_id
            entity_name
            entity_type
          }
          score
          severity
          status
          tag_id
          tenant
          user_tag
          whitelisted_info {
            entity_name
            whitelisted
            whitelisted_for
          }
          source_entities {
            entity_id
            entity_name
            entity_type
            entity_category
          }
          destination_entities {
            entity_id
            entity_name
            entity_type
            entity_category
          }
        }
      }
      offset
      size
      total_items
    }
  }
`;

export const useQueryAlertsMLPaginated = (
  options: LazyQueryHookOptions<
    IQueryAlertsPaginated<MlAlert>,
    OperationVariables
  >,
) =>
  useLazyQuery<IQueryAlertsPaginated<MlAlert>>(
    QUERY_ALERTS_ML_PAGINATED,
    options,
  );
