export type ExcludedEntityInput = {
  content_id: string;
  entity_id: string;
  entity_name: string;
  entity_type: AlertEntityType;
  entity_ip: string;
  entity_ip_range: string;
  tenant: string;
  created_timestamp: number;
};

export type SortParameter = {
  sort_by: string;
  sort_dir: string;
};

export enum AlertEntityType {
  DOMAIN,
  HOST,
  IP,
  IP_RANGE,
  MAC,
  USER,
}

export interface AlertAndDetectionTenantInfo {
  tenant_id: string;
  tenant_display_name: string;
}

export interface detailsProps {
  label: string;
  input: string | undefined;
  stringify?: boolean;
  lineBreak?: boolean[];
  truncate?: boolean;
}

export type ExcludedEntity = {
  content_id: string;
  entity_id: string;
  entity_name: string;
  entity_type: AlertEntityType | string;
  entity_ip: string | null;
  entity_ip_range: string | null;
  tenant: string;
  _score: number;
  created_by: string;
  created_by_alias: string;
  created_timestamp: number;
};

export interface MetadataLogSource {
  category: string;
  product?: string;
  service?: string;
}

export interface MetadataEventStrategy {
  attack_probability_threshold: number;
  method: string;
  predicted_tag_id: string;
  alert_sid: string;
}

export interface Documentation {
  significance: string;
  validation: string;
  nextsteps: string;
  queries: string;
}

export interface CustomSearchRuleAlertMetadata {
  query_string: string;
  query_filter: string;
  throttle_time: string;
  time_interval: string;
}

export interface AlertMetadata {
  active: boolean;
  author?: string;
  category?: string;
  cve_reference?: string;
  severity_description?: string;
  content_id: string;
  date?: string;
  description?: string;
  event_generation_strategy?: MetadataEventStrategy;
  falsepositives?: string[];
  fields?: string[];
  id?: string;
  level?: string;
  logsource: MetadataLogSource;
  mitre_mappings?: MitreMappings;
  references?: string[];
  related?: MetadataRelated[];
  severity: number;
  status?: string;
  tags?: string[];
  title: string;
  rule?: string;
  tenant_alert: string;
  _score?: number;
  updated_by?: string;
  updated_by_alias?: string;
  updated_timestamp?: number;
  content_doc?: Documentation;
  severity_info: SeverityInfo;
  chatbot_chats?: AlertMetadataChatbotChats;
  custom_search_rule?: CustomSearchRuleAlertMetadata;
}

export interface IGetAlertMetadata {
  getAlertMetadata: PaginatedAlertMetadata;
}

export interface CommentsForParentPaginated {
  comments: string[];
}

export interface GetCommentsForParentPaginated {
  getCommentsForParentPaginated: CommentsForParentPaginated;
}

export interface AlertMetadataChatbotChats {
  suri_describe_rule: string;
  suri_alert_explain: string;
  suri_investigate_alert: string;
  non_suri_alert_attack: string;
  non_suri_alert_ttp: string;
}

export interface ISetSeverityOnAlertsInput {
  content_id: string;
  severity_info: {
    custom_severity: number;
    custom_severity_enabled: boolean;
  };
  tenant?: string;
  updated_timestamp: number;
}

export interface SeverityInfo {
  custom_severity: number;
  custom_severity_enabled: boolean;
  default_severity: number;
  last_updated_by_user_alias: string;
  last_updated_by_username: string;
  last_updated_timestamp: number;
}

export interface MitreSubtechniques {
  link: string;
  name: string;
  tag: string;
}

export interface MitreTechnique {
  link: string;
  name: string;
  subtechniques: MitreSubtechniques[];
  tag: string;
}

export interface MitreTactic {
  link: string;
  name: string;
  tag: string;
  techniques: MitreTechnique[];
}

export interface MitreMappings {
  tactics: MitreTactic[];
}

export interface PaginatedAlertMetadata {
  matches: string[];
  metadata: AlertMetadata[];
  offset: number;
  size: number;
}

export interface MetadataRelated {
  id: string;
  title: string;
  type: string;
}

export interface AlertMetadataPaginated {
  matches: string[];
  metadata: AlertMetadata[];
  offset: number;
  size: number;
  total_items: number;
}

export interface AlertTechniqueSummary {
  alert_count: number;
  entity_count: number;
  first_seen: number;
  last_seen: number;
  mitre_attack_tactic: string;
  mitre_attack_tactic_priority: number;
  mitre_attack_technique: string;
  tenant: string;
  total_alerts: number;
}

export interface AlertTacticSummary {
  alert_count: number;
  entity_count: number;
  first_seen: number;
  last_seen: number;
  mitre_attack_tactic: string;
  mitre_attack_tactic_priority: number;
  mitre_attack_techniques: AlertTechniqueSummary[];
  tenant: string;
  total_alerts: number;
}

export interface DetectionTechniqueSummary {
  detection_count: number;
  entity_count: number;
  first_seen: number;
  last_seen: number;
  mitre_attack_tactic: string;
  mitre_attack_tactic_priority: number;
  mitre_attack_technique: string;
  tenant: string;
  total_detections: number;
}

export interface DetectionTacticSummary {
  detection_count: number;
  entity_count: number;
  first_seen: number;
  last_seen: number;
  mitre_attack_tactic: string;
  mitre_attack_tactic_priority: number;
  mitre_attack_techniques: DetectionTechniqueSummary[];
  tenant: string;
  total_detections: number;
}

export type TimeIntervalInput = {
  start: number;
  end: number;
};

export type CurrentPreviousSummary = {
  current: number;
  pct_chg: number;
  previous: number;
};

export type ScoreDistribution = {
  avg: number;
  max: number;
  min: number;
};

export interface AlertPeriodSummary {
  count: CurrentPreviousSummary;
  score: ScoreDistribution;
  total_alerts: CurrentPreviousSummary;
  total_entities: CurrentPreviousSummary;
}

export interface DetectionPeriodSummary {
  count: CurrentPreviousSummary;
  score: ScoreDistribution;
  total_alert_categories: CurrentPreviousSummary;
  total_entities: CurrentPreviousSummary;
}

export interface DetectionAlertsChartTimeline {
  dest_alerts: string;
  detection_alerts: string;
  source_alerts: string;
  source_dest_alerts: string;
  start: number;
  end: number;
}

export type BlogOutput = {
  category: string;
  dc_creator: string;
  dc_date: string;
  description: string;
  link: string;
  pub_date: string;
  title: string;
};

export interface FeatureMetaDataGetInput {
  pipeline: string;
  category: string;
  feature_names: string[];
}

export interface FeatureMetadata {
  name: string;
  display_name: string;
  description: string;
  category: string;
  pipeline: string;
  definition: string;
  data_type: string;
}

export interface ModelFeatureSummary {
  feature_count: number;
  feature_histograms: FeatureHistogramData[];
  feature_minmax: FeatureMinMaxData[];
  model_id: string;
}

export interface FeatureMinMaxData {
  max: number;
  mean: number;
  min: number;
  name: string;
  pct_10: number;
  pct_25: number;
  pct_5: number;
  pct_50: number;
  pct_75: number;
  pct_90: number;
  pct_95: number;
  std: number;
}

export interface FeatureHistogramData {
  bins: FeatureHistogramBin[];
  name: string;
}

export interface FeatureHistogramBin {
  count: number;
  cut: number;
}

export interface ModelSummaryFeature {
  actual: number;
  contribution: number;
  difference: number;
  feature_name: string;
}

export interface ModelSummaryContribution {
  actual: number;
  contribution: number;
  description?: string;
  difference: number;
  feature_name: string;
  display_name?: string;
}

export interface FeatureImportance {
  importance: number;
  name: string;
}

export interface AlgorithmParameters {
  algorithm: string;
  compute_norm_values: boolean;
  data_strategy: string;
  eliminate_outliers: boolean;
  label_threshold: number;
  learners: number;
  remove_correlated: number;
  remove_sparse: boolean;
  tune_training_set: boolean;
}

export interface ModelLabelsTagCount {
  count: number;
  name: string;
}

export interface ModelLabelCounts {
  normal: number;
  unknown: number;
}

export interface ModelLabels {
  tag: ModelLabelsTagCount[];
  used_global_labels: ModelLabelCounts;
  used_local_labels: ModelLabelCounts;
}

export interface ModelParameters {
  mode: string;
}

export interface ModelPerformanceCurves {
  pr_curve_thresholds: number[];
  pr_curve_x: number[];
  pr_curve_y: number[];
  roc_curve_thresholds: number[];
  roc_curve_x: number[];
  roc_curve_y: number[];
}

export interface ModelPerformanceStats {
  auc: number;
  cost: number;
  estimated_daily_alerts: number;
  pdr: number;
  pr_auc: number;
}

export interface PreprocessingFiltersPredict {
  filter: string;
}

export interface PreprocessingFiltersTrain {
  filter: string;
  tag_id: string;
}

export interface PreprocessingFilters {
  predict: PreprocessingFiltersPredict[];
  train: PreprocessingFiltersTrain[];
}

export interface TrainingSetDates {
  end_date: string;
  start_date: string;
}

export interface ModelSummary {
  algorithm_params: AlgorithmParameters;
  del_features: string;
  feature_details: ModelFeatureSummary;
  feature_importance: [FeatureImportance];
  labels: ModelLabels;
  model_description: string;
  model_id: string;
  model_parameters: ModelParameters;
  model_trained_by: string;
  model_trained_on: string;
  performance_curves: ModelPerformanceCurves;
  performance_stats: ModelPerformanceStats;
  preprocessing_filters: PreprocessingFilters;
  satisfactory_auc: number;
  satisfactory_tpr: number;
  successful_training: boolean;
  tag_ids: string[];
  training_set_dates: TrainingSetDates;
  training_set_size: number;
  used_features: string[];
}

export interface TenantAlertTimeline {
  start: number;
  end: number;
  total_alerts: string;
  alert_count: string;
  entity_count: string;
}

export interface AlertSummary {
  alert_count: number;
  alert_name?: string;
  alert_type?: string;
  content_id?: string;
  entity_count?: number;
  entity_id?: string;
  entity_name: string;
  entity_type: string;
  first_seen: number;
  last_seen: number;
  score: number;
  severity: number;
  tenant?: string;
  tenant_alert?: string;
  tenant_entity: string;
  total_alerts: number;
}

export interface PaginatedDetectionSummaries {
  offset: number;
  size: number;
  summaries: DetectionSummary[];
  total_items: number;
  sort: [SortParameter];
}

export interface PaginatedAlertSummaries {
  offset: number;
  size: number;
  summaries: AlertSummary[];
  total_items: number;
}

export interface EntityDetectionSummary {
  tenant_info: AlertAndDetectionTenantInfo;
  entity_category: 'source' | 'destination';
  entity_id?: string;
  entity_name: string;
  entity_type: string;
  last_seen: number;
  tenant_entity: string;
}

export interface DetectionSummary {
  detection_count: number;
  alert_name?: string;
  alert_type?: string;
  content_id?: string;
  entity_category?: 'source' | 'destination';
  entity_count?: number;
  entity_id: string;
  entity_name: string;
  entity_type: string;
  first_seen: number;
  last_seen: number;
  severity: number;
  tenant?: string;
  tenant_detection?: string;
  tenant_entity: string;
  tenant_info: AlertAndDetectionTenantInfo;
  total_detections: number;
  detection_id?: string;
  total_items?: number;
}

export interface PaginatedDetectionSummaries {
  offset: number;
  size: number;
  summaries: DetectionSummary[];
  total_items: number;
  sort: [SortParameter];
}

export interface AlertEntity {
  entity_id: string;
  entity_name: string;
  entity_type: string;
  entity_category: 'source' | 'destination';
}

export interface AlertInfo {
  alert_name: string;
  alert_type: string;
  content_id: string;
  entity?: AlertEntity;
}

export interface AlertKeys {
  tenant_alert: string;
  tenant_alert_entity: string;
  tenant_entity: string;
}

export interface AlertTimestamp {
  end: number;
  observed: number;
  start: number;
  ttl: number;
}

export type SessionSummaryData = Record<SessionSummaryHeaders, string[]>;

export enum SessionSummaryHeaders {
  IOC = 'ioc',
  AlertSummary = 'alert_summary',
  AlertBeacons = 'alert_beacons',
  UnusualFindings = 'unusual_findings',
  AttackTactics = 'attack_tactics',
}

export interface Alert {
  alert_entity: AlertEntity;
  alert_id: string;
  alert_info: AlertInfo;
  alert_keys: AlertKeys;
  alert_timestamp: AlertTimestamp;
  event_ids: string[];
  false_positive: boolean;
  related_entities: AlertEntity[];
  score: number;
  severity: number;
  tenant: string;
  source_entities: AlertEntity[];
  destination_entities: AlertEntity[];
  payload?: string;
  payload_summary?: string;
  session_summary?: Record<SessionSummaryHeaders, string[]>;
}

export interface EntityAlert extends Alert {
  total_alerts: number;
}

export interface GenericAlert extends Alert {
  fields: string;
}

export interface IntelAlert extends Alert {
  cif_confidence: number;
  cif_description: string;
  cif_firstseen: string;
  cif_lastseen: string;
  cif_source: string;
  cif_tags: string;
  destination_ip: string;
  domain: string;
  file_desc: string;
  file_mime_type: string;
  fuid: string;
  matched: string[];
  orig_h: string;
  orig_p: number;
  path: string;
  resp_h: string;
  resp_p: number;
  seen_indicator: string;
  seen_indicator_type: string;
  seen_node: string;
  seen_where: string;
  source_ip: string;
  sources: string[];
  system_name: string;
  uid: string;
}

export interface MLFeatures {
  actual: number;
  contribution: number;
  difference: number;
  feature_name: string;
  display_name?: string;
}

export interface MLModels {
  features: MLFeatures[];
  model_alias: string[];
  model_name: string;
  model_score: number;
  predicted_tag_id: string;
}

export interface MLWhiteListEntry {
  entity_name: string;
  whitelisted: boolean;
  whitelisted_for: string;
}

export interface MlAlert extends Alert {
  average_technique_score_map: string;
  description: string;
  mode: string;
  models: MLModels[];
  pipeline: string;
  related_entities: AlertEntity[];
  status: string;
  tag_id: string;
  user_tag: string;
  whitelisted_info: MLWhiteListEntry[];
}

export type CustomSearchRuleAlert = Alert;

export interface RemoteLocation {
  city: string;
  country_code: string;
  lattitude: string;
  longitude: string;
  region: string;
}

export interface Severity {
  level: number;
  name: string;
}

export interface SuricataMetadata {
  key: string;
  value: string;
}

export interface SuricataAlert extends Alert {
  action: string;
  category: string;
  community_id: string;
  destination_ip: string;
  destination_port: number;
  flow_id: number;
  gid: number;
  metadata: SuricataMetadata[];
  pcap_cnt: number;
  related_entities: AlertEntity[];
  rev: number;
  service: string;
  signature_id: string;
  source_ip: string;
  source_port: number;
  suri_id: string;
  tx_id: number;
  uid: string;
  payload: string;
  payload_summary: string;
}

export interface NoticeAlert extends Alert {
  actions: string[];
  destination_ip: string;
  dropped: string;
  dst: string;
  file_desc: string;
  file_mime_type: string;
  fuid: string;
  msg: string;
  n: string;
  note: string;
  orig_h: string;
  orig_p: number;
  p: number;
  path: string;
  peer_descr: string;
  proto: string;
  related_entities: AlertEntity[];
  remote_location: RemoteLocation;
  resp_h: string;
  resp_p: number;
  severity_info: Severity;
  source_ip: string;
  src: string;
  sub: string;
  suppress_for: number;
  system_name: string;
  uid: string;
}

export interface PaginatedAlerts<AlertType extends Alert = Alert> {
  alerts: AlertType[];
  offset: number;
  size: number;
  total_items: number;
}

export interface IQueryAlertsPaginated<AlertType extends Alert = Alert> {
  queryAlertsPaginated: PaginatedAlerts<AlertType>;
}

export interface IQueryAlertsPaginatedWithMetadata<
  AlertType extends Alert = Alert,
> {
  queryAlertsPaginated: PaginatedAlerts<AlertType>;
  getAlertMetadata: PaginatedAlertMetadata;
}

export type AlertType = 'intel' | 'ml' | 'notice' | 'suricata_corelight';

export type PaginatedExcludedEntities = {
  excluded_entities: ExcludedEntity[];
  offset: number;
  size: number;
  total_items: number;
  sort?: [SortParameter];
};

export interface SetAlertStatusBySearchTermResult {
  updated_record_count: number;
  tenant: string;
}

export interface EntityDetectionTimelineItem {
  alert_name: string;
  alert_type: string;
  content_id: string;
  entity_id: string;
  entity_name: string;
  entity_type: string;
  first_seen: number;
  last_seen: number;
  severity: number;
  tenant: string;
  tenant_detection: string;
  tenant_entity: string;
  tenant_info: AlertAndDetectionTenantInfo;
  total_detections: number;
  is_custom_severity: boolean;
}

export interface EntityDetectionTimeline {
  entity_id: string;
  entity_name: string;
  entity_type: string;
  data: EntityDetectionTimelineItem[];
  is_truncated: boolean;
  offset: number;
  size: number;
  severity: number;
  tenant: string;
  tenant_entity: string;
  tenant_info: AlertAndDetectionTenantInfo;
  total_detections: number;
  total_items: number;
  total_grouped_entities: number;
}

export enum SetAlertStatusScore {
  low,
  medium,
  high,
}

export enum SetAlertStatusType {
  suricata_corelight,
  notice,
  ml_results,
}

export interface SetAlertStatusBySearchTermInput {
  tenant: string;
  active: boolean;
  search_term: string;
  filters: SetAlertStatusBySearchTermFiltersInput;
}

export interface SetAlertStatusBySearchTermFiltersInput {
  active: boolean[] | [];
  severity: SetAlertStatusScore[] | [];
  types: SetAlertStatusType[] | [];
}

export interface DetectionAssignmentInfo {
  assigned_at: number;
  assigned_to_username: string;
  assigned_to_user_alias: string;
}

export interface DetectionTimestamp {
  start: number;
  end: number;
}

export interface DetectionUpdateInfo {
  last_updated_by: string;
  last_updated_timestamp: number;
}

export interface DetectionRank {
  severity: number;
  is_custom_severity: boolean;
}

export interface DetectionKeys {
  tenant_detection: string;
  tenant_detection_entity: string;
  tenant_entity: string;
}

export interface DetectionEscalationData {
  escalation_status: boolean;
  escalated_by_user_alias: string;
  escalated_timestamp: number;
  escalation_service_name: string;
}

export type PaginatedAlertEntityName = {
  offset: number;
  size: number;
  alert_entities: AlertEntity[];
  total_items: number;
  sort: SortParameter[];
};

export interface Detection {
  detection_id: string;
  detection_keys: DetectionKeys;
  alert_info: AlertInfo;
  alert_entity: AlertEntity;
  assignment_info: DetectionAssignmentInfo;
  created_timestamp?: number;
  detection_status: string;
  detection_timestamp: DetectionTimestamp;
  earliest_start_timestamp: number;
  latest_start_timestamp: number;
  rank: DetectionRank;
  total_alert_count: number;
  update_info: DetectionUpdateInfo;
  escalation_data: DetectionEscalationData;
  logscale_query: string;
  tenant: string;
  tenant_info: AlertAndDetectionTenantInfo;
  paginated_destination_entities: PaginatedAlertEntityName;
  paginated_source_entities: PaginatedAlertEntityName;
  payload?: string;
}

export interface PaginatedDetections {
  detections: Detection[];
  offset: number;
  size: number;
  total_items: number;
  sort: [SortParameter];
}

export interface PaginatedDetectionsTotalItems {
  total_items: number;
}

export interface IQueryDetectionsTotalItems {
  pageIndex?: number;
  queryDetectionsPaginated: PaginatedDetectionsTotalItems;
}

export interface IQueryDetectionsPaginated {
  pageIndex?: number;
  queryDetectionsPaginated: PaginatedDetections;
}

export interface DetectionsFieldSuggestions {
  suggestions: string[];
  tenant: string;
}

export interface IQueryDetectionsFieldSuggestions {
  queryDetectionsFieldSuggestions: string[];
}

export interface ISetStatusOnDetections {
  setStatusOnDetections: PaginatedDetections;
}

export enum IDetectionStatus {
  open,
  closed,
}

export interface IDetectionUpdateInfoInput {
  last_updated_timestamp?: number;
}

export interface ISetStatusOnDetectionsInput {
  detection_id?: string;
  detection_status?: IDetectionStatus;
  tenant?: string;
  update_info?: IDetectionUpdateInfoInput;
}

export interface QueryAlertMetadataPaginatedVariables {
  start: number;
  end: number;
  query: string | null;
  offset: number;
  size: number;
  sort: { sort_by: string; sort_dir: string }[];
}

export interface DetectionAlertBucketSummary {
  bucket_start: number;
  bucket_end: number;
  score: number;
  alert_count: number;
}

export interface DetectionAlertSummary {
  detection_id: string;
  severity: number;
  alert_name: string;
  entity_name: string;
  earliest_start_timestamp: number;
  latest_start_timestamp: number;
  buckets: DetectionAlertBucketSummary[];
}

export interface DetectionAlertGroupSummary {
  tenant: string;
  alert_name: string;
  entity_name: string;
  severity: number;
  detections: DetectionAlertSummary[];
}

export interface DetectionAlertGroupCountSummary {
  row_count: number;
  summary: DetectionAlertGroupSummary[];
}

export interface IGetCurrentDetectionTimelineSummary {
  getCurrentDetectionTimelineSummary: DetectionAlertGroupCountSummary;
}

export interface IGetDetectionSourceTimelineSummary {
  getDetectionSourceTimelineSummary: DetectionAlertGroupCountSummary;
}

export interface IGetDetectionDestinationTimelineSummary {
  getDetectionDestinationTimelineSummary: DetectionAlertGroupCountSummary;
}

export interface IGetDetectionSourceDestinationTimelineSummary {
  getDetectionSourceDestinationTimelineSummary: DetectionAlertGroupCountSummary;
}

export interface IGetDetectionTimelineSummary {
  getCurrentDetectionTimelineSummary: DetectionAlertGroupCountSummary;
  getDetectionSourceTimelineSummary: DetectionAlertGroupCountSummary;
  getDetectionDestinationTimelineSummary: DetectionAlertGroupCountSummary;
  getDetectionSourceDestinationTimelineSummary: DetectionAlertGroupCountSummary;
}

export type DetectionAlertSummarySeverities = 'low' | 'medium' | 'high';

export interface NameIDMapping {
  alert_name: string;
  detection_ids: string[];
}

export interface IPObj {
  source: string[];
  dest: string[];
}

export interface IPResponse {
  source: string[];
  dest: string[];
  source_dest: IPObj;
}

export interface FilterResponse {
  severity_filter: DetectionAlertSummarySeverities[];
  alert_name_filter: NameIDMapping[];
  ip_filter: IPResponse;
}

export interface IGetChartTimelineFilters {
  getChartTimelineFilters: FilterResponse;
}
