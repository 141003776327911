import { gql } from '@apollo/client';

export const QUERY_ALERTS_NOTICE_PAGINATED = gql`
  query queryAlertsNoticePaginated(
    $query: String!
    $offset: Int
    $size: Int
    $sort: [SortParameterInput]
  ) {
    queryAlertsPaginated(
      query: $query
      offset: $offset
      size: $size
      sort: $sort
    ) {
      alerts {
        ... on NoticeAlert {
          alert_id
          alert_entity {
            entity_id
            entity_name
            entity_type
          }
          alert_info {
            alert_name
            alert_type
          }
          alert_keys {
            tenant_alert
            tenant_entity
            tenant_alert_entity
          }
          alert_timestamp {
            observed
          }
          msg
          uid
          tenant
          source_entities {
            entity_id
            entity_name
            entity_type
            entity_category
          }
          destination_entities {
            entity_id
            entity_name
            entity_type
            entity_category
          }
        }
      }
      offset
      size
      total_items
    }
  }
`;
