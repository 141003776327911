import {
  gql,
  OperationVariables,
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

import { IGetUserHistoryForEndpointDeviceEntity } from './types';

export const GET_USER_HISTORY_FOR_ENDPOINT_DEVICE_ENTITY_QUERY = gql`
  query getUserHistoryForEndpointDeviceEntity(
    $tenant: String
    $as_of: Float
    $entity: EntityIdInput
    $offset: Int
    $size: Int
    $max_results: Int
    $use_cache: Boolean
    $sort: [SortParameterInput]
  ) {
    getUserHistoryForEndpointDeviceEntity(
      tenant: $tenant
      as_of: $as_of
      entity: $entity
      offset: $offset
      size: $size
      max_results: $max_results
      use_cache: $use_cache
      sort: $sort
    ) {
      as_of
      max_results
      offset
      size
      total_items
      use_cache
      user_histories {
        data_source
        obsts
        username
      }
    }
  }
`;

export const useGetUserHistoryForEndpointDeviceEntityQuery = (
  options: QueryHookOptions<
    IGetUserHistoryForEndpointDeviceEntity,
    OperationVariables
  >,
) =>
  useQuery<IGetUserHistoryForEndpointDeviceEntity>(
    GET_USER_HISTORY_FOR_ENDPOINT_DEVICE_ENTITY_QUERY,
    options,
  );
