import { ApolloError } from '@apollo/client';
import { Box, Button, HStack, Text, VStack } from '@chakra-ui/react';
import { MuiIcon } from '@gamma/icons';
import { EntityInformationTrigger } from '@gamma/investigator/components';
import { ROUTES } from '@gamma/investigator/constants';
import { AuthContext } from '@gamma/investigator/context';
import { i18n } from '@gamma/investigator/localization';
import {
  AlertEntity,
  Detection,
  ExcludedEntity,
} from '@gamma/investigator/queries';
import { AccordionPanel } from '@gamma/layout';
import { Popover } from '@gamma/overlay';
import { Dispatch, SetStateAction, useContext } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { IDetectionContext } from '../../../Detection/Detection';

const { systemSettingsIntegrationsConfig } = ROUTES;

const { detection: detectionText, entityEDR } = i18n.pages.detections;

const { source, destination, entity } = detectionText;

export interface DetectionEntitiesProps {
  detectionInfo?: Detection;
  activeEntity: AlertEntity | null;
  setActiveEntity: Dispatch<SetStateAction<AlertEntity | null>>;
  excludeEntitiesData?: ExcludedEntity[];
  excludeEntitiesError?: ApolloError;
  queryVariables?: {
    query: string;
    offset: number;
    size: number;
    sort: {
      sort_by: string;
      sort_dir: string;
    }[];
  };
}

export const DetectionEntities = ({
  detectionInfo,
  // activeEntity,
  // setActiveEntity,
  // queryVariables,
  // excludeEntitiesData,
  // excludeEntitiesError,
}: DetectionEntitiesProps) => {
  const navigate = useNavigate();

  const { edrConfig } = useOutletContext<IDetectionContext>() || {};
  const { isOrgTenant } = useContext(AuthContext);

  const { paginated_destination_entities, paginated_source_entities } =
    detectionInfo || {};

  // const [entityType, setEntityType] = useState<string>();

  const sourceEntities = paginated_source_entities?.alert_entities;
  const totalSourceEntities = paginated_source_entities?.total_items;
  const destinationEntities = paginated_destination_entities?.alert_entities;
  const totalDestinationEntities = paginated_destination_entities?.total_items;

  const hasSource = Boolean(sourceEntities?.length);
  const hasDestination = Boolean(destinationEntities?.length);

  const NUM_ENTITIES_SHOWN = 10;
  // const sourceEntitiesOverflow =
  //   sourceEntities && sourceEntities.length - NUM_ENTITIES_SHOWN;
  // const destinationEntitiesOverflow =
  //   destinationEntities && destinationEntities.length - NUM_ENTITIES_SHOWN;

  // const entities =
  //   entityType === 'source'
  //     ? sourceEntities
  //     : entityType === 'destination'
  //       ? destinationEntities
  //       : undefined;

  // const totalEntites =
  //   entityType === 'source'
  //     ? totalSourceEntities
  //     : entityType === 'destination'
  //       ? totalDestinationEntities
  //       : undefined;

  // useEffect(() => {
  //   setSlideDrawerOnClose?.(() => () => {
  //     setActiveEntity(null);
  //     setEntityType(undefined);
  //   });
  // }, [setSlideDrawerOnClose, setIsSlideDrawerOpen, setActiveEntity]);

  // const setEntityDrawerOpen = ({
  //   entity,
  //   entitySrcDest,
  // }: {
  //   entity: AlertEntity;
  //   entitySrcDest: string;
  // }) => {
  //   setPortalType?.('entity');
  //   setActiveEntity(entity);
  //   setEntityType(entitySrcDest);
  //   setIsSlideDrawerOpen?.(true);
  // };

  return (
    <AccordionPanel
      w="100%"
      title={
        <HStack>
          <Text>{entity}</Text>
          {!edrConfig?.enabled && (
            <Box h="20px" data-testid="entity-enrichment-info-tooltip">
              <Popover
                isLazy
                width="320px"
                trigger="hover"
                placement="right"
                title={entityEDR.entityEnrichment}
                body={
                  isOrgTenant
                    ? entityEDR.getEntityEnrichmentOrg
                    : entityEDR.getEntityEnrichment
                }
                footer={
                  !isOrgTenant && (
                    <Button
                      size="md"
                      variant="solid"
                      colorScheme="blue"
                      data-testid="entity-enrichment-info-button"
                      rightIcon={<MuiIcon>arrow_forward</MuiIcon>}
                      onClick={() => {
                        navigate({
                          pathname: systemSettingsIntegrationsConfig,
                        });
                      }}
                    >
                      {entityEDR.integrationPage}
                    </Button>
                  )
                }
              >
                <MuiIcon color="text.secondary">info</MuiIcon>
              </Popover>
            </Box>
          )}
        </HStack>
      }
      reduceMotion={true}
      storageKey="detection-details-entity"
    >
      <HStack alignItems="start">
        <VStack w="50%" alignItems="start">
          <HStack>
            <MuiIcon size="lg" color={hasSource ? 'text.primary' : 'gray'}>
              crisis_alert
            </MuiIcon>
            <Text
              as="h5"
              textStyle="h5"
              color={hasSource ? 'text.primary' : 'gray'}
            >
              {source}
              {sourceEntities &&
              sourceEntities.length !== 1 &&
              totalSourceEntities
                ? ` (${sourceEntities?.length}${totalSourceEntities > sourceEntities?.length ? ` of ${totalSourceEntities}` : ''})`
                : ''}
            </Text>
          </HStack>
          {sourceEntities?.slice(0, NUM_ENTITIES_SHOWN).map((entity, index) => {
            const detectionSummary = detectionInfo?.tenant_info
              ? {
                  ...detectionInfo,
                  ...entity,
                  detection_count: 1,
                  total_detections: 1,
                  severity: detectionInfo?.rank?.severity || 0,
                  last_seen: detectionInfo?.latest_start_timestamp || 0,
                  first_seen: detectionInfo?.earliest_start_timestamp || 0,
                  tenant_entity: detectionInfo?.tenant_info?.tenant_id || '',
                }
              : null;
            return (
              detectionSummary && (
                <EntityInformationTrigger
                  key={index}
                  isStandalone={true}
                  conditionallySuppress={true}
                  detectionSummary={detectionSummary}
                />
              )
            );
          })}
        </VStack>
        <VStack w="50%" alignItems="start">
          <HStack>
            <MuiIcon size="lg" color={hasDestination ? 'text.primary' : 'gray'}>
              location_on
            </MuiIcon>
            <Text
              as="h5"
              textStyle="h5"
              color={hasDestination ? 'text.primary' : 'gray'}
            >
              {destination}
              {destinationEntities &&
              destinationEntities.length !== 1 &&
              totalDestinationEntities
                ? ` (${destinationEntities.length}${totalDestinationEntities > destinationEntities.length ? ` of ${totalDestinationEntities}` : ''})`
                : ''}
            </Text>
          </HStack>
          <HStack flexFlow="row wrap">
            {destinationEntities
              ?.slice(0, NUM_ENTITIES_SHOWN)
              .map((entity, index) => {
                const detectionSummary = detectionInfo?.tenant_info
                  ? {
                      ...detectionInfo,
                      ...entity,
                      detection_count: 1,
                      total_detections: 1,
                      severity: detectionInfo?.rank?.severity || 0,
                      last_seen: detectionInfo?.latest_start_timestamp || 0,
                      first_seen: detectionInfo?.earliest_start_timestamp || 0,
                      tenant_entity:
                        detectionInfo?.tenant_info?.tenant_id || '',
                    }
                  : null;
                return (
                  detectionSummary && (
                    <EntityInformationTrigger
                      key={index}
                      isStandalone={true}
                      conditionallySuppress={true}
                      detectionSummary={detectionSummary}
                    />
                  )
                );
              })}
          </HStack>
        </VStack>
      </HStack>
    </AccordionPanel>
  );
};
