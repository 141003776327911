import { useBreakpoint } from '@chakra-ui/media-query';
import { ResponsiveValue } from '@chakra-ui/styled-system';
import { useTheme } from '@chakra-ui/system';
import { arrayToObjectNotation, fromEntries, isArray } from '@chakra-ui/utils';

import { getClosestValue } from './utils';

/* 
 ! THIS IS A BLATANT COPY PASTE OF THE useBreakpointValue HOOK FROM @chakra-ui
 ! The hook as provided did not support the `ResponsiveValue` type so I edited
 ! it be able to here. 
 TODO: If chakra updates the hook to allow the ResponsiveValue type, delete.
 */

/**
 * React hook for getting the value for the current breakpoint from the
 * provided responsive values object.
 *
 * @param values
 * @param defaultBreakpoint default breakpoint name
 * (in non-window environments like SSR)
 *
 * For SSR, you can use a package like [is-mobile](https://github.com/kaimallea/isMobile)
 * to get the default breakpoint value from the user-agent
 *
 * @example
 * const width = useBreakpointValue({ base: '150px', md: '250px' })
 */
export function useBreakpointValue<T = any>( // eslint-disable-line @typescript-eslint/no-explicit-any
  values: ResponsiveValue<T>,
  defaultBreakpoint?: string,
): T | undefined {
  const breakpoint = useBreakpoint(defaultBreakpoint);
  const theme = useTheme();

  if (
    typeof values === 'string' ||
    (!isArray(values) && Object.keys(values ?? {}).length === 0)
  ) {
    return values as T;
  }

  if (!breakpoint) return undefined;

  /**
   * Get the non-number breakpoint keys from the provided breakpoints
   */
  const breakpoints = Object.keys(theme.breakpoints);

  const obj = isArray(values)
    ? fromEntries<Record<string, T>>(
        Object.entries(arrayToObjectNotation(values, breakpoints)).map(
          ([key, value]) => [key, value],
        ),
      )
    : Object.keys(values ?? {}).length > 0
      ? (values as Partial<Record<string, T>>)
      : {};

  return getClosestValue(obj, breakpoint, breakpoints);
}
