import {
  Button,
  ButtonGroup,
  HStack,
  IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  PopoverTrigger,
  Portal,
  Text,
  Tooltip,
  useClipboard,
  useToast,
} from '@chakra-ui/react';
import { MuiIcon } from '@gamma/icons';
import { ROUTES, URLS } from '@gamma/investigator/constants';
import {
  AuthContext,
  EntityInformationContext,
} from '@gamma/investigator/context';
import { useDateRangeQueryString } from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import { logScaleEntityPivot } from '@gamma/investigator/utilities';
import { RouterLink } from '@gamma/navigation';
import { LoadingSpinner } from '@gamma/progress';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import validator from 'validator';

const ipaddr = require('ipaddr.js');

const { CENSYS, SHODAN, VIRUS_TOTAL } = URLS;

const { copied, copyValue, controls, entityEDR, viewDetections } =
  i18n.pages.detections;

const { entityIsolateTooltip, entitySuppressTooltip } = i18n.pages.entities;

interface EntityPopoverButtonProps {
  entityName: string;
  isStandalone?: boolean;
  entityCategory?: string;
  detectionCount?: number;
  contextMenuOpen?: boolean;
  isHoveredEntity?: boolean;
  handleEntityClick: () => void;
  setContextMenuOpen: Dispatch<SetStateAction<boolean | undefined>>;
}

export const EntityPopoverButton = ({
  entityName,
  isStandalone,
  entityCategory,
  contextMenuOpen,
  isHoveredEntity,
  handleEntityClick,
  setContextMenuOpen,
}: EntityPopoverButtonProps) => {
  const { start, end } = useDateRangeQueryString();

  const { userRole } = useContext(AuthContext);

  const { onIsolateOpen, onSuppressOpen, activeEntity } = useContext(
    EntityInformationContext,
  );

  const {
    isLoading,
    entityEdrStatus,
    entityIsIsolated,
    showIsolateEntity,
    entityIsSuppressed,
    showSuppressEntity,
  } = activeEntity || {};

  const toast = useToast();
  const { hasCopied, onCopy } = useClipboard(entityName);

  const detectionsPivotParams = `start=${start}&end=${end}&${
    entityCategory ?? 'source'
  }_name=${entityName}`;

  useEffect(() => {
    if (hasCopied) {
      toast({
        position: 'bottom-right',
        status: 'success',
        description: copied,
      });
    }
  }, [hasCopied, toast]);

  const entityEdrIsolated = useMemo(() => {
    return entityIsIsolated !== undefined
      ? entityIsIsolated
      : entityEdrStatus?.entity_edr_status === 'isolated' ||
          entityEdrStatus?.entity_edr_status === 'isolation_pending';
  }, [entityIsIsolated, entityEdrStatus?.entity_edr_status]);

  const logScaleEntityQuery =
    start && end && entityName
      ? logScaleEntityPivot({
          start,
          end,
          entityName: entityName,
        })
      : '';
  const buttonRef = useRef<HTMLButtonElement>(null);

  return (
    <PopoverTrigger>
      <ButtonGroup isAttached>
        <Button
          size="sm"
          variant="ghost"
          ref={buttonRef}
          colorScheme="gray"
          color="text.primary"
          border={'1px solid'}
          justifyContent="start"
          borderTopRightRadius={isStandalone ? '4px !important' : 'base'}
          borderBottomRightRadius={isStandalone ? '4px !important' : 'base'}
          borderColor={
            isStandalone || isHoveredEntity ? 'whiteAlpha.300' : 'transparent'
          }
          backgroundColor={
            isStandalone && isHoveredEntity
              ? '#393939 !important'
              : isStandalone || isHoveredEntity
                ? 'gray.600'
                : ''
          }
          width={isStandalone ? 'auto' : isHoveredEntity ? '110px' : 'auto'}
          maxW={isStandalone ? 'auto' : '150px'}
          onClick={(e) => {
            e.preventDefault();
            handleEntityClick();
          }}
          onContextMenu={(e) => {
            e.preventDefault();
            setContextMenuOpen?.(true);
          }}
        >
          <Text
            as="span"
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            pointerEvents="none"
          >
            {entityName}
          </Text>
        </Button>
        {isHoveredEntity && (
          <Menu
            isOpen={contextMenuOpen}
            offset={
              !isStandalone
                ? [-110, 0]
                : [
                    buttonRef.current?.clientWidth
                      ? -(buttonRef.current?.clientWidth + 2)
                      : 0,
                    0,
                  ]
            }
          >
            {!isStandalone ? (
              <MenuButton
                as={IconButton}
                size="sm"
                variant="ghost"
                colorScheme="gray"
                color="text.primary"
                aria-label=""
                border={'1px solid'}
                borderColor="whiteAlpha.300"
                borderLeft={0}
                backgroundColor="gray.600"
                icon={<MuiIcon>expand_more</MuiIcon>}
                onClick={(e) => {
                  e.preventDefault();
                  setContextMenuOpen?.(true);
                }}
                onContextMenu={(e) => {
                  e.preventDefault();
                  setContextMenuOpen?.(true);
                }}
              />
            ) : (
              <MenuButton
                border={0}
                onClick={(e) => {
                  e.preventDefault();
                  setContextMenuOpen?.(true);
                }}
                onContextMenu={(e) => {
                  e.preventDefault();
                  setContextMenuOpen?.(true);
                }}
              />
            )}
            <Portal>
              <MenuList>
                <MenuGroup>
                  <MenuItem
                    target="_blank"
                    as={RouterLink}
                    icon={<MuiIcon>database</MuiIcon>}
                    to={{
                      pathname: ROUTES.logSearch,
                      search: logScaleEntityQuery,
                    }}
                  >
                    <HStack>
                      <Text>{controls.investigateLogs}</Text>
                      <MuiIcon>north_east</MuiIcon>
                    </HStack>
                  </MenuItem>
                  <MenuItem
                    as={RouterLink}
                    icon={<MuiIcon>list_alt</MuiIcon>}
                    to={{
                      pathname: ROUTES.detections,
                      search: detectionsPivotParams,
                    }}
                  >
                    {viewDetections}
                  </MenuItem>
                </MenuGroup>
                <MenuDivider />
                <MenuGroup>
                  {userRole !== 'viewer' && (
                    <>
                      <Tooltip
                        label={!showIsolateEntity ? entityIsolateTooltip : ''}
                      >
                        <MenuItem
                          alignItems="center"
                          isDisabled={isLoading || !showIsolateEntity}
                          onClick={onIsolateOpen}
                          icon={
                            isLoading ? (
                              <LoadingSpinner size="xs" minH="auto" />
                            ) : (
                              <MuiIcon>shield</MuiIcon>
                            )
                          }
                        >
                          {entityEdrIsolated
                            ? entityEDR.edrAction.liftIsolation
                            : entityEDR.edrAction.isolateEntity}
                        </MenuItem>
                      </Tooltip>
                      <Tooltip
                        label={!showSuppressEntity ? entitySuppressTooltip : ''}
                      >
                        <MenuItem
                          isDisabled={isLoading || !showSuppressEntity}
                          onClick={onSuppressOpen}
                          icon={
                            isLoading ? (
                              <LoadingSpinner size="xs" minH="auto" />
                            ) : entityIsSuppressed ? (
                              <MuiIcon>notifications</MuiIcon>
                            ) : (
                              <MuiIcon>notifications_off</MuiIcon>
                            )
                          }
                        >
                          {entityIsSuppressed
                            ? controls.unSuppressEntity
                            : controls.suppressEntity}
                        </MenuItem>
                      </Tooltip>
                    </>
                  )}
                  <MenuItem
                    onClick={onCopy}
                    icon={<MuiIcon>content_copy</MuiIcon>}
                  >
                    {copyValue}
                  </MenuItem>
                </MenuGroup>
                {entityName &&
                  validator.isIP(entityName) &&
                  ipaddr.parse(entityName)?.range() !== 'private' && (
                    <>
                      <MenuDivider />
                      <MenuGroup>
                        <MenuItem
                          as={Link}
                          isExternal
                          icon={
                            <Image
                              w="16px"
                              h="16px"
                              src="/assets/images/logo-virustotal.png"
                            />
                          }
                          href={`${VIRUS_TOTAL}/${entityName}`}
                        >
                          <HStack>
                            <Text>{`${controls.searchIn} VirusTotal`}</Text>
                            <MuiIcon>north_east</MuiIcon>
                          </HStack>
                        </MenuItem>
                        <MenuItem
                          as={Link}
                          isExternal
                          icon={
                            <Image
                              w="16px"
                              h="16px"
                              src="/assets/images/logo-shodan.png"
                            />
                          }
                          href={`${SHODAN}/${entityName}`}
                        >
                          <HStack>
                            <Text>{`${controls.searchIn} Shodan`}</Text>
                            <MuiIcon>north_east</MuiIcon>
                          </HStack>
                        </MenuItem>
                        <MenuItem
                          as={Link}
                          isExternal
                          icon={
                            <Image
                              w="16px"
                              h="16px"
                              src="/assets/images/logo-censys.png"
                            />
                          }
                          href={`${CENSYS}/${entityName}`}
                        >
                          <HStack>
                            <Text>{`${controls.searchIn} Censys`}</Text>
                            <MuiIcon>north_east</MuiIcon>
                          </HStack>
                        </MenuItem>
                      </MenuGroup>
                    </>
                  )}
              </MenuList>
            </Portal>
          </Menu>
        )}
      </ButtonGroup>
    </PopoverTrigger>
  );
};
