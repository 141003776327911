import { UnorderedList } from '@chakra-ui/layout';
import { chakra } from '@chakra-ui/system';
import { ReactNode } from 'react';

export interface NavbarProps {
  style?: any;
  /** The Navbar items to render within the Navbar */
  children: ReactNode;
}

export const Navbar = ({ style, children }: NavbarProps) => {
  return (
    <chakra.nav
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      flex="1 1 auto"
      data-testid="gamma-navbar"
      style={style}
    >
      <UnorderedList styleType="none" ml={0} display="contents">
        {children}
      </UnorderedList>
    </chakra.nav>
  );
};
