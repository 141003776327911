import {
  Box,
  Button,
  Center,
  HStack,
  IconButton,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  Text,
  VStack,
} from '@chakra-ui/react';
import { DataTable } from '@gamma/data-table';
import { CrowdStrikeSmall, MuiIcon } from '@gamma/icons';
import {
  AuthContext,
  EntityInformationContext,
  EntityInstance,
} from '@gamma/investigator/context';
import {
  useDateRangeQueryString,
  useManualPagination,
} from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import { EntityPanelRow } from '@gamma/investigator/pages/detections';
import {
  NICHistory,
  useGetNicHistoryForEndpointDeviceEntityQuery,
  useGetUserHistoryForEndpointDeviceEntityQuery,
  usePullEntityEdrStatus,
  UserHistory,
} from '@gamma/investigator/queries';
import { FlushPanelContent, Panel } from '@gamma/layout';
import { RouterLink } from '@gamma/navigation';
import { Alert } from '@gamma/overlay';
import { GraphQLReqStatus } from '@gamma/progress';
import moment from 'moment';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Column as TableColumn } from 'react-table';

const { integrations } = i18n.pages;
const { learnMore, additionalEdrIntegration } = integrations;

const { entityEDR } = i18n.pages.detections;
const { headings, edrAction } = entityEDR;
const { userHistory, interfaceHistory } = headings;

const dataTableInterfaceColumns: TableColumn<NICHistory>[] = [
  {
    Header: entityEDR.tableHeaders.ipAddress,
    accessor: 'ip_address',
    disableSortBy: true,
  },
  {
    Header: entityEDR.macAddress,
    accessor: 'mac_address',
    disableSortBy: true,
  },
  {
    Header: entityEDR.timestamp,
    accessor: 'obsts',
    Cell: (props) => {
      return <>{moment.unix(props.value).format('MMMM Do, h:mma')}</>;
    },
  },
];

const dataTableUserColumns: TableColumn<UserHistory>[] = [
  {
    Header: entityEDR.tableHeaders.user,
    accessor: 'username',
    disableSortBy: true,
  },
  {
    Header: entityEDR.tableHeaders.lastLogin,
    accessor: 'obsts',
    Cell: (props) => {
      return <>{moment.unix(props.value).format('MMMM Do, h:mma')}</>;
    },
  },
];

interface CrowdstrikeModalSectionProps {
  activeEntity?: EntityInstance;
}

export const CrowdstrikeModalSection = ({
  activeEntity,
}: CrowdstrikeModalSectionProps) => {
  const { userRole } = useContext(AuthContext);

  const { start, end } = useDateRangeQueryString();

  const { setActiveEntity } = useContext(EntityInformationContext);

  const {
    isLoading,
    tenantEntity,
    tenantInfo,
    lastSeen,
    edrPayload,
    edrConfigData,
    entityEdrStatus,
  } = activeEntity || {};

  const { tenant_id } = tenantInfo || {};

  const {
    os,
    entity_id,
    device_id,
    host_names,
    platform,
    platform_type,
    external_ips,
    mac_addresses,
    sensor_version,
    cloud_account_id,
    cloud_instance_id,
    cloud_provider,
    ad_domain_name,
    last_updated_timestamp,
    ad_organizational_unit_name,
  } = edrPayload || {};

  const { entity_edr_status } = entityEdrStatus || {};

  const edrStatusAlert = useMemo(() => {
    if (entity_edr_status) {
      switch (entity_edr_status) {
        case 'normal':
          return {
            colorScheme: 'green',
            statusText: edrAction.normal,
          };
        case 'isolated':
          return {
            colorScheme: 'red',
            statusText: edrAction.isolated,
          };
        case 'isolation_pending':
          return {
            colorScheme: 'yellow',
            statusText: edrAction.pendingIsolation,
          };
        case 'lift_isolation_pending':
          return {
            colorScheme: 'yellow',
            statusText: edrAction.pendingLift,
          };
      }
    }
    return undefined;
  }, [entity_edr_status]);

  const [nicInitialFetch, nicSetInitialFetch] = useState<boolean>(false);

  const {
    sortBy: nicSortBy,
    offset: nicOffset,
    pageSize: nicPageSize,
    pageIndex: nicPageIndex,
    pageCount: nicPageCount,
    onFetchData: nicOnFetchData,
    getPageCount: nicGetPageCount,
    getSortsForAPI: nicGetSortsForAPI,
  } = useManualPagination<NICHistory>({
    initialFetch: nicInitialFetch,
    sortBy: [{ id: 'obsts', desc: true }],
  });

  const {
    loading: getNicHistoryLoading,
    data: getNicHistoryData,
    error: getNicHistoryError,
  } = useGetNicHistoryForEndpointDeviceEntityQuery({
    skip: !lastSeen || !entity_id,
    variables: {
      as_of: lastSeen,
      entity: {
        entity_id,
        entity_type: 'endpoint_device',
      },
      tenant: tenant_id,
      offset: nicOffset,
      size: nicPageSize,
      sort: nicGetSortsForAPI(nicSortBy),
    },
    onCompleted: ({ getNicHistoryForEndpointDeviceEntity }) => {
      nicSetInitialFetch(false);
      nicGetPageCount(getNicHistoryForEndpointDeviceEntity);
    },
  });

  const [userInitialFetch, userSetInitialFetch] = useState<boolean>(false);

  const {
    sortBy: userSortBy,
    offset: userOffset,
    pageSize: userPageSize,
    pageIndex: userPageIndex,
    pageCount: userPageCount,
    onFetchData: userOnFetchData,
    getPageCount: userGetPageCount,
    getSortsForAPI: userGetSortsForAPI,
  } = useManualPagination<UserHistory>({
    initialFetch: userInitialFetch,
    sortBy: [{ id: 'obsts', desc: true }],
  });

  const {
    loading: getUserHistoryLoading,
    data: getUserHistoryData,
    error: getUserHistoryError,
  } = useGetUserHistoryForEndpointDeviceEntityQuery({
    skip: !lastSeen || !entity_id,
    variables: {
      tenant: tenant_id,
      as_of: lastSeen,
      entity: {
        entity_id,
        entity_type: 'endpoint_device',
      },
      offset: userOffset,
      size: userPageSize,
      sort: userGetSortsForAPI(userSortBy),
    },
    onCompleted: ({ getUserHistoryForEndpointDeviceEntity }) => {
      userSetInitialFetch(false);
      userGetPageCount(getUserHistoryForEndpointDeviceEntity);
    },
  });

  const [pullEntityEdrStatus, { loading: pullEntityEdrStatusLoading }] =
    usePullEntityEdrStatus({
      fetchPolicy: 'network-only',
      variables: {
        tenant: tenant_id,
        entity_id: activeEntity?.edrPayload?.entity_id,
      },
      onCompleted: ({ pullEntityEdrStatus }) => {
        const { entity_edr_status, tenant, updated_timestamp } =
          pullEntityEdrStatus;
        setActiveEntity?.((prevState) => ({
          ...prevState,
          entityIsIsolated:
            entity_edr_status === 'isolated' ||
            entity_edr_status === 'isolation_pending',
          entityEdrStatus: {
            tenant,
            entity_edr_status,
            updated_timestamp,
          },
        }));
      },
    });

  useEffect(() => {
    nicSetInitialFetch(true);
    userSetInitialFetch(true);
  }, [start, end]);

  return (
    <VStack w="100%" alignItems="start">
      <HStack>
        <CrowdStrikeSmall />
        <Text textStyle="h5">Crowdstrike</Text>
      </HStack>
      {isLoading ? (
        <Center w="100%">
          <GraphQLReqStatus loading={isLoading} />
        </Center>
      ) : !edrConfigData?.enabled ? (
        <Alert status="info" variant="subtle" isClosable={false}>
          <VStack alignItems="start">
            <Text>{additionalEdrIntegration}</Text>
            <Button
              as={RouterLink}
              target="_blank"
              variant="solid"
              colorScheme="blue"
              to={`/docs/settings/crowdstrike-edr.html`}
            >
              {learnMore}
            </Button>
          </VStack>
        </Alert>
      ) : (
        <HStack w="100%" spacing={8} alignItems="start">
          <VStack minW="200px">
            <EntityPanelRow heading={edrAction.entityStatus}>
              <HStack spacing={0}>
                {edrStatusAlert ? (
                  <>
                    <Tag
                      size="md"
                      variant="solid"
                      colorScheme={edrStatusAlert?.colorScheme}
                    >
                      {edrStatusAlert?.statusText}
                    </Tag>
                    <IconButton
                      size="sm"
                      aria-label={edrAction.refreshEntityStatus}
                      onClick={() => {
                        if (tenantInfo && tenantEntity) {
                          pullEntityEdrStatus();
                        }
                      }}
                      isDisabled={
                        pullEntityEdrStatusLoading || userRole === 'viewer'
                      }
                      icon={
                        pullEntityEdrStatusLoading ? (
                          <Box w="20px" h="20px">
                            <Spinner size="xs" color="white" />
                          </Box>
                        ) : (
                          <MuiIcon>refresh</MuiIcon>
                        )
                      }
                    />
                  </>
                ) : (
                  <Text>---</Text>
                )}
              </HStack>
            </EntityPanelRow>
            <EntityPanelRow heading={entityEDR.timestamp}>
              {last_updated_timestamp
                ? moment
                    .unix(last_updated_timestamp)
                    .format('MMMM D, YYYY h:mma')
                : undefined}
            </EntityPanelRow>
            <EntityPanelRow heading={entityEDR.macAddress}>
              {mac_addresses?.join(', ')}
            </EntityPanelRow>
            <EntityPanelRow heading={entityEDR.hostName}>
              {host_names?.join(', ')}
            </EntityPanelRow>
            <EntityPanelRow heading={entityEDR.platform}>
              {platform}
            </EntityPanelRow>
            <EntityPanelRow heading={entityEDR.platformType}>
              {platform_type}
            </EntityPanelRow>
            <EntityPanelRow heading={entityEDR.os}>{os}</EntityPanelRow>
            <EntityPanelRow heading={entityEDR.externalIp}>
              {external_ips?.join(', ')}
            </EntityPanelRow>
            <EntityPanelRow heading={entityEDR.deviceId}>
              {device_id}
            </EntityPanelRow>
          </VStack>
          <VStack>
            <EntityPanelRow heading={entityEDR.sensorVersion}>
              {sensor_version}
            </EntityPanelRow>
            <EntityPanelRow heading={entityEDR.cloudAccountId}>
              {cloud_account_id}
            </EntityPanelRow>
            <EntityPanelRow heading={entityEDR.cloudInstanceId}>
              {cloud_instance_id}
            </EntityPanelRow>
            <EntityPanelRow heading={entityEDR.cloudProvider}>
              {cloud_provider}
            </EntityPanelRow>
            <EntityPanelRow heading={entityEDR.adDomainName}>
              {ad_domain_name}
            </EntityPanelRow>
            <EntityPanelRow heading={entityEDR.adOrganizationalUnitName}>
              {ad_organizational_unit_name}
            </EntityPanelRow>
          </VStack>
          <VStack ml="auto" w="510px">
            <Tabs w="100%" variant="line">
              <TabList>
                <Tab>
                  <HStack>
                    <Text>{interfaceHistory}</Text>
                    <Tag>
                      {getNicHistoryData?.getNicHistoryForEndpointDeviceEntity
                        ?.total_items || 0}
                    </Tag>
                  </HStack>
                </Tab>
                <Tab>
                  <HStack>
                    <Text>{userHistory}</Text>
                    <Tag>
                      {getUserHistoryData?.getUserHistoryForEndpointDeviceEntity
                        ?.total_items || 0}
                    </Tag>
                  </HStack>
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  {getNicHistoryLoading || getNicHistoryError ? (
                    <GraphQLReqStatus
                      error={getNicHistoryError}
                      loading={getNicHistoryLoading}
                    />
                  ) : (
                    <Panel>
                      <FlushPanelContent>
                        <DataTable
                          isLined
                          pageSize={nicPageSize}
                          pageCount={nicPageCount}
                          autoResetPage={false}
                          columns={dataTableInterfaceColumns}
                          autoResetSortBy={false}
                          isPaginationManual={true}
                          onFetchData={nicOnFetchData}
                          itemCount={
                            getNicHistoryData
                              ?.getNicHistoryForEndpointDeviceEntity
                              ?.total_items || 0
                          }
                          initialState={{
                            pageIndex: nicPageIndex,
                            sortBy: nicSortBy,
                          }}
                          data={
                            getNicHistoryData
                              ?.getNicHistoryForEndpointDeviceEntity
                              ?.nic_histories || []
                          }
                          isPaginable={Boolean(
                            !getNicHistoryLoading &&
                              !getNicHistoryError &&
                              getNicHistoryData
                                ?.getNicHistoryForEndpointDeviceEntity
                                ?.nic_histories?.length,
                          )}
                        />
                      </FlushPanelContent>
                    </Panel>
                  )}
                </TabPanel>
                <TabPanel>
                  {getUserHistoryLoading || getUserHistoryError ? (
                    <GraphQLReqStatus
                      error={getUserHistoryError}
                      loading={getUserHistoryLoading}
                    />
                  ) : (
                    <Panel>
                      <FlushPanelContent>
                        <DataTable
                          isLined
                          pageSize={userPageSize}
                          pageCount={userPageCount}
                          autoResetPage={false}
                          columns={dataTableUserColumns}
                          autoResetSortBy={false}
                          isPaginationManual={true}
                          onFetchData={userOnFetchData}
                          itemCount={
                            getUserHistoryData
                              ?.getUserHistoryForEndpointDeviceEntity
                              ?.total_items || 0
                          }
                          initialState={{
                            pageIndex: userPageIndex,
                            sortBy: userSortBy,
                          }}
                          data={
                            getUserHistoryData
                              ?.getUserHistoryForEndpointDeviceEntity
                              ?.user_histories || []
                          }
                          isPaginable={Boolean(
                            !getUserHistoryLoading &&
                              !getUserHistoryError &&
                              getUserHistoryData
                                ?.getUserHistoryForEndpointDeviceEntity
                                ?.user_histories?.length,
                          )}
                        />
                      </FlushPanelContent>
                    </Panel>
                  )}
                </TabPanel>
              </TabPanels>
            </Tabs>
          </VStack>
        </HStack>
      )}
    </VStack>
  );
};
