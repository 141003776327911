interface logScaleEntityPivotPops {
  start: number;
  end: number;
  entityName: string;
}

export const logScaleEntityPivot = ({
  start,
  end,
  entityName,
}: logScaleEntityPivotPops) =>
  `query=${`id.orig_h%3D${entityName}%20or%20id.resp_h%3D${entityName}%20or%20src%3D${entityName}`}&live=false&humioStart=${
    start && (start - 330) * 1000
  }&humioEnd=${
    end && (end + 330) * 1000
  }&fullscreen=false&widgetType=table-view&showOnlyFirstLine=false&start=${start}&end=${end}`;
