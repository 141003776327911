import {
  Button,
  HStack,
  IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  useClipboard,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { MuiIcon } from '@gamma/icons';
import { ROUTES, URLS } from '@gamma/investigator/constants';
import {
  AuthContext,
  EntityInformationContext,
} from '@gamma/investigator/context';
import { i18n } from '@gamma/investigator/localization';
import { logScaleEntityPivot } from '@gamma/investigator/utilities';
import { RouterLink } from '@gamma/navigation';
import { LoadingSpinner } from '@gamma/progress';
import { useContext, useEffect, useMemo } from 'react';

import validator from 'validator';

const ipaddr = require('ipaddr.js');

const { CENSYS, SHODAN, VIRUS_TOTAL } = URLS;

const { entityIsolateTooltip, entitySuppressTooltip } = i18n.pages.entities;

const { copied, copyValue, controls, entityEDR, viewDetections } =
  i18n.pages.detections;

export interface EntityInformationActionsProps {
  end?: number;
  start?: number;
  isModal?: boolean;
  entityName?: string;
  entityCategory?: string;
  showIsolateEntity?: boolean;
  entityEdrIsolated?: boolean;
  entityIsSuppressed?: boolean;
  onClose?: () => void;
  onIsolateEntity?: () => void;
  onSuppressEntity?: () => void;
}

export const EntityInformationActions = ({
  end,
  start,
  isModal,
  entityName,
  entityCategory,
  showIsolateEntity,
  entityEdrIsolated,
  entityIsSuppressed,
  onClose,
  onIsolateEntity,
  onSuppressEntity,
}: EntityInformationActionsProps) => {
  const toast = useToast();
  const { hasCopied, onCopy } = useClipboard(entityName || '');

  const { userRole } = useContext(AuthContext);

  const { activeEntity } = useContext(EntityInformationContext);

  const { isLoading, showSuppressEntity } = activeEntity || {};

  useEffect(() => {
    if (hasCopied) {
      toast({
        position: 'bottom-right',
        status: 'success',
        description: copied,
      });
    }
  }, [hasCopied, toast]);

  const logScaleEntityQuery = useMemo(
    () =>
      start && end && entityName
        ? logScaleEntityPivot({
            start,
            end,
            entityName,
          })
        : '',
    [end, entityName, start],
  );

  return (
    <VStack pb={!isModal ? 2 : 0} spacing={4}>
      <HStack w="100%">
        <Menu>
          <MenuButton
            as={Button}
            flexGrow={1}
            variant="solid"
            colorScheme="blue"
            size={isModal ? 'sm' : 'xs'}
          >
            <HStack ml={!isModal ? '102px' : 0}>
              <MuiIcon>frame_inspect</MuiIcon>
              <Text>Investigate</Text>
              <MuiIcon>expand_more</MuiIcon>
            </HStack>
          </MenuButton>
          <MenuList>
            <MenuItem
              minW={!isModal ? '375px' : 'auto'}
              target="_blank"
              as={RouterLink}
              icon={<MuiIcon>database</MuiIcon>}
              to={{
                pathname: ROUTES.logSearch,
                search: logScaleEntityQuery,
              }}
            >
              <HStack>
                <Text>Investigate Logs</Text>
                <MuiIcon>north_east</MuiIcon>
              </HStack>
            </MenuItem>
            <MenuItem
              as={RouterLink}
              onClick={onClose}
              icon={<MuiIcon>list_alt</MuiIcon>}
              to={{
                pathname: ROUTES.detections,
                search: `start=${start}&end=${end}&${
                  entityCategory ?? 'source'
                }_name=${entityName}`,
              }}
            >
              {viewDetections}
            </MenuItem>
          </MenuList>
        </Menu>
        <Menu offset={[isModal ? -192 : -199, 8]}>
          <MenuButton
            as={IconButton}
            variant="solid"
            colorScheme="gray"
            isLoading={isLoading}
            size={isModal ? 'sm' : 'xs'}
          >
            <HStack justifyContent="center">
              <MuiIcon>more_horiz</MuiIcon>
            </HStack>
          </MenuButton>
          <MenuList>
            {userRole !== 'viewer' && (
              <>
                <Tooltip label={!showIsolateEntity ? entityIsolateTooltip : ''}>
                  <MenuItem
                    isDisabled={isLoading || !showIsolateEntity}
                    onClick={onIsolateEntity}
                    icon={
                      isLoading ? (
                        <LoadingSpinner size="xs" minH="auto" />
                      ) : (
                        <MuiIcon>shield</MuiIcon>
                      )
                    }
                  >
                    {entityEdrIsolated
                      ? entityEDR.edrAction.liftIsolation
                      : entityEDR.edrAction.isolateEntity}
                  </MenuItem>
                </Tooltip>
                <Tooltip
                  label={!showSuppressEntity ? entitySuppressTooltip : ''}
                >
                  <MenuItem
                    isDisabled={isLoading || !showSuppressEntity}
                    onClick={onSuppressEntity}
                    icon={
                      isLoading ? (
                        <LoadingSpinner size="xs" minH="auto" />
                      ) : entityIsSuppressed ? (
                        <MuiIcon>notifications</MuiIcon>
                      ) : (
                        <MuiIcon>notifications_off</MuiIcon>
                      )
                    }
                  >
                    {entityIsSuppressed
                      ? controls.unSuppressEntity
                      : controls.suppressEntity}
                  </MenuItem>
                </Tooltip>
              </>
            )}
            <MenuItem onClick={onCopy} icon={<MuiIcon>content_copy</MuiIcon>}>
              {copyValue}
            </MenuItem>
            {isModal &&
              entityName &&
              validator.isIP(entityName) &&
              ipaddr.parse(entityName)?.range() !== 'private' && (
                <>
                  <MenuDivider />
                  <MenuItem
                    as={Link}
                    isExternal
                    href={`${VIRUS_TOTAL}/${entityName}`}
                    _hover={{ textDecoration: 'none' }}
                    icon={
                      <Image
                        w="16px"
                        h="16px"
                        src="/assets/images/logo-virustotal.png"
                      />
                    }
                  >
                    <HStack pl={1}>
                      <Text>VirusTotal</Text>
                      <MuiIcon>north_east</MuiIcon>
                    </HStack>
                  </MenuItem>
                  <MenuItem
                    as={Link}
                    isExternal
                    href={`${SHODAN}/${entityName}`}
                    _hover={{ textDecoration: 'none' }}
                    icon={
                      <Image
                        w="16px"
                        h="16px"
                        src="/assets/images/logo-shodan.png"
                      />
                    }
                  >
                    <HStack pl={1}>
                      <Text>Shodan</Text>
                      <MuiIcon>north_east</MuiIcon>
                    </HStack>
                  </MenuItem>
                  <MenuItem
                    as={Link}
                    isExternal
                    href={`${CENSYS}/${entityName}`}
                    _hover={{ textDecoration: 'none' }}
                    icon={
                      <Image
                        w="16px"
                        h="16px"
                        src="/assets/images/logo-censys.png"
                      />
                    }
                  >
                    <HStack pl={1}>
                      <Text>Censys</Text>
                      <MuiIcon>north_east</MuiIcon>
                    </HStack>
                  </MenuItem>
                </>
              )}
          </MenuList>
        </Menu>
      </HStack>
      {!isModal &&
        entityName &&
        validator.isIP(entityName) &&
        ipaddr.parse(entityName)?.range() !== 'private' && (
          <HStack w="100%">
            <Link
              isExternal
              flexGrow={1}
              textAlign="center"
              _hover={{ textDecoration: 'none' }}
              href={`${VIRUS_TOTAL}/${entityName}`}
            >
              <HStack>
                <Image
                  w="13px"
                  h="13px"
                  src="/assets/images/logo-virustotal.png"
                />
                <Text fontSize="sm">VirusTotal</Text>
                <MuiIcon>north_east</MuiIcon>
              </HStack>
            </Link>
            <Link
              isExternal
              flexGrow={1}
              textAlign="center"
              _hover={{ textDecoration: 'none' }}
              href={`${SHODAN}/${entityName}`}
            >
              <HStack>
                <Image w="16px" h="16px" src="/assets/images/logo-shodan.png" />
                <Text fontSize="sm">Shodan</Text>
                <MuiIcon>north_east</MuiIcon>
              </HStack>
            </Link>
            <Link
              isExternal
              flexGrow={1}
              textAlign="center"
              _hover={{ textDecoration: 'none' }}
              href={`${CENSYS}/${entityName}`}
            >
              <HStack>
                <Image w="16px" h="16px" src="/assets/images/logo-censys.png" />
                <Text fontSize="sm">Censys</Text>
                <MuiIcon>north_east</MuiIcon>
              </HStack>
            </Link>
          </HStack>
        )}
    </VStack>
  );
};
