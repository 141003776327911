import { Box, Grid, GridItem, GridProps, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';

type DetailsGridProps = {
  children: ReactNode;
} & GridProps;

export const DetailsGrid = (props: DetailsGridProps) => {
  return (
    <Grid templateColumns="120px 1fr" rowGap={3} columnGap={2} {...props}>
      {props.children}
    </Grid>
  );
};

interface DetailsGridRowProps {
  title: ReactNode;
  children: ReactNode;
  'data-testid'?: string;
  isDetailsPage?: boolean;
}

export const DetailsGridRow = ({
  title,
  children,
  isDetailsPage,
  'data-testid': dataTestId,
}: DetailsGridRowProps) => {
  return isDetailsPage ? (
    <Box w="100%">
      <GridItem display="flex" alignItems="start">
        <Text data-testid={`${dataTestId}-label`} color="text.secondary">
          {title}
        </Text>
      </GridItem>
      <GridItem
        display="flex"
        flex="1"
        alignItems="center"
        data-testid={`${dataTestId}-value`}
      >
        {children}
      </GridItem>
    </Box>
  ) : (
    <>
      <GridItem display="flex" alignItems="start">
        <span data-testid={`${dataTestId}-label`} color="text.secondary">
          {title}
        </span>
      </GridItem>
      <GridItem
        display="flex"
        flex="1"
        alignItems="center"
        data-testid={`${dataTestId}-value`}
      >
        {children}
      </GridItem>
    </>
  );
};
