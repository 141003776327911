import { ApolloError, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  VStack,
} from '@chakra-ui/react';
import { ColumnFilterPopover, DataTable } from '@gamma/data-table';
import { MuiIcon } from '@gamma/icons';
import {
  EntityInformationTrigger,
  TimestampCell,
} from '@gamma/investigator/components';
import { graphqlErrorRedirects, ROUTES } from '@gamma/investigator/constants';
import { AuthContext, SlideDrawerContext } from '@gamma/investigator/context';
import {
  useDateRangeQueryString,
  useIsNewSearch,
  useManualPagination,
} from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import {
  Alert,
  AlertEntity,
  AlertSummary,
  CustomSearchRuleAlert,
  Detection,
  DetectionSummary,
  IQueryAlertsPaginated,
  MlAlert,
  MLModels,
  NoticeAlert,
  QUERY_ALERTS_ML_PAGINATED,
  QUERY_ALERTS_NOTICE_PAGINATED,
  QUERY_ALERTS_SEARCH_BASED_PAGINATED,
  QUERY_ALERTS_SURICATA_PAGINATED,
  SuricataAlert,
} from '@gamma/investigator/queries';
import { FlushPanelContent, Panel } from '@gamma/layout';
import { RouterLink } from '@gamma/navigation';
import { GraphQLReqStatus, LoadingSpinner } from '@gamma/progress';
import {
  DetectionAlertsFilters,
  IDetectionContext,
} from 'libs/investigator/pages/detections/src/lib/Pages/Detection/Detection';
import moment from 'moment';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useOutletContext } from 'react-router-dom';
import { SortingRule, Column as TableColumn } from 'react-table';
import validator from 'validator';
import {
  AlertRowSplitML,
  AlertRowSplitNotice,
  AlertRowSplitSearchBased,
  AlertRowSplitSuricata,
  MLButtonsCol,
  NoticeButtonsCol,
  SuricataButtonsCol,
} from './Components';

export const DetectionAlertsFooter = ({
  loading,
  error,
  data,
}: {
  loading: boolean;
  error: ApolloError | undefined;
  data: Alert[] | undefined;
}) => {
  if (loading || error) {
    return (
      <Panel>
        <GraphQLReqStatus
          loading={loading}
          error={error}
          extended={graphqlErrorRedirects}
        />
      </Panel>
    );
  }
  if (!data?.length) {
    return <Panel>{i18n.pages.alerts.na}</Panel>;
  } else {
    return null;
  }
};

interface AlertEntityPopoverProps {
  entities?: AlertEntity[];
  detectionSummary?: DetectionSummary;
}

export const AlertEntityPopover = ({
  entities,
  detectionSummary,
}: AlertEntityPopoverProps) => {
  const { entity_category, entity_id, entity_name, entity_type } =
    entities?.[0] || {};
  return entities && entities?.length === 1 ? (
    !validator.isIP(entities?.[0]?.entity_name) ? (
      <Box ml={2}>{entities?.[0]?.entity_name}</Box>
    ) : (
      entity_id &&
      entity_name &&
      entity_type &&
      entity_category &&
      detectionSummary && (
        <EntityInformationTrigger
          detectionSummary={{
            ...detectionSummary,
            entity_category,
            entity_id,
            entity_name,
            entity_type,
          }}
        />
      )
    )
  ) : entities && entities?.length > 1 ? (
    <HStack>
      {!validator.isIP(entities?.[0]?.entity_name) ? (
        <Box ml={2}>{entities?.[0]?.entity_name}</Box>
      ) : (
        entity_id &&
        entity_name &&
        entity_type &&
        entity_category &&
        detectionSummary && (
          <EntityInformationTrigger
            detectionSummary={{
              ...detectionSummary,
              entity_category,
              entity_id,
              entity_name,
              entity_type,
            }}
          />
        )
      )}
      <Popover trigger="hover" placement="top">
        <PopoverTrigger>
          <Text color="text.link">+{entities?.length - 1}</Text>
        </PopoverTrigger>
        <Portal>
          <PopoverContent>
            <PopoverArrow />
            <PopoverBody>
              <VStack alignItems="start">
                {entities
                  ?.slice(1, entities?.length)
                  .map((entity) => <Text>{entity?.entity_name}</Text>)}
              </VStack>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    </HStack>
  ) : (
    <Box ml={2}>---</Box>
  );
};

export type SanitizedMlAlert = {
  alert_timestamp: any;
  alert_info: any;
  alert_entity: any;
  pipeline: string;
  models: MLModels[];
  timestamp: number;
  logscale_query: string;
  source_entities?: AlertEntity[];
  destination_entities?: AlertEntity[];
  related_entities: AlertEntity[];
  average_technique_score_map: string;
};

export type SanitizedSearchBasedAlert = {
  alert_info: any;
  alert_entity: any;
  alert_timestamp: any;
  source_entities?: AlertEntity[];
  destination_entities?: AlertEntity[];
  timestamp: number;
};

export type SanitizedNoticeAlert = {
  uid: string;
  msg?: string;
  alert_info: any;
  alert_entity: any;
  alert_timestamp: any;
  source_entities?: AlertEntity[];
  destination_entities?: AlertEntity[];
  timestamp?: number;
  logscale_query?: string;
  tenant?: string;
};

export type SanitizedSuricataAlert = {
  uid: string;
  msg?: string;
  alert_info: any;
  alert_entity: any;
  alert_timestamp: any;
  source_entities?: AlertEntity[];
  destination_entities?: AlertEntity[];
  timestamp?: number;
  logscale_query?: string;
  community_id?: string;
  destination_ip?: string;
  payload?: string;
  tenant?: string;
};

interface DetectionAlertsProps {
  detectionData: any;
  suricataRuleText: string;
  getAppConfigurationsData: string | null;
}

export const DetectionAlerts = ({
  detectionData,
  suricataRuleText,
  getAppConfigurationsData,
}: DetectionAlertsProps) => {
  const { metadataLoading, metadataData } =
    useOutletContext<IDetectionContext>();

  const {
    portalType,
    setPortalType,
    slideDrawerTitle,
    slideDrawerBody,
    setIsSlideDrawerOpen,
    setSlideDrawerOnClose,
  } = useContext(SlideDrawerContext);

  const { controls, alertActivity, alertsActivity } = i18n.pages.detections;

  const { logSearch } = ROUTES;

  const [downloadUrl, setDownloadUrl] = useState<string>('');

  const { start, end } = useDateRangeQueryString();

  const {
    alert_entity,
    alert_info,
    earliest_start_timestamp,
    latest_start_timestamp,
    logscale_query,
    tenant,
  } = detectionData || {};

  const detectionAlertType = detectionData?.alert_info?.alert_type;

  const { userPrivileges } = useContext(AuthContext);

  const isGPTPrivateDataEnabled = Boolean(
    userPrivileges?.[tenant]?.chatgpt_private_data,
  );

  const {
    sortBy: alertsSortBy,
    pageSize: alertsPageSize,
    pageCount: alertsPageCount,
    pageIndex: alertsPageIndex,
    onFetchData: onFetchAlertsData,
    getPageCount: alertsGetPageCount,
    getSortsForAPI: alertsGetSortsForAPI,
    formatNestedSorts: alertsFormatNestSorts,
  } = useManualPagination<AlertSummary>({
    sortBy: [{ id: 'timestamp', desc: true }],
  });

  const query =
    detectionAlertType === 'ml'
      ? QUERY_ALERTS_ML_PAGINATED
      : detectionAlertType === 'notice'
        ? QUERY_ALERTS_NOTICE_PAGINATED
        : detectionAlertType === 'suricata_corelight'
          ? QUERY_ALERTS_SURICATA_PAGINATED
          : detectionAlertType === 'custom_search_rule'
            ? QUERY_ALERTS_SEARCH_BASED_PAGINATED
            : QUERY_ALERTS_SEARCH_BASED_PAGINATED;

  const alertsSortedTimestamp = alertsFormatNestSorts(alertsSortBy);

  const [filters, setFilters] = useState<
    { id: string; value: string }[] | null
  >(null);

  const { isNewSearch } = useIsNewSearch({
    filters,
  });

  const [alertsSize, setAlertsSize] = useState<number>(10);
  const [alertsOffset, setAlertsOffset] = useState<number>(0);

  const noFilterVariables = {
    size: alertsSize,
    offset: isNewSearch ? 0 : alertsOffset,
    sort: alertsGetSortsForAPI(alertsSortedTimestamp),
    query: `{"query": {"bool": {"must": [{"term": {"alert_info.content_id": "${
      detectionData?.alert_info?.content_id
    }"}}, {"term": {"alert_entity.entity_id": "${
      detectionData?.alert_entity?.entity_id
    }"}}, {"range": {"alert_timestamp.end": {"gte": ${
      detectionData?.earliest_start_timestamp
    }}}}, {"range": {"alert_timestamp.start": {"lte": ${
      detectionData?.latest_start_timestamp
    }}}}${detectionData?.tenant_info?.tenant_id ? ',' : ''}${
      detectionData?.tenant_info?.tenant_id
        ? `{"terms":{"tenant":["${detectionData?.tenant_info?.tenant_id}"]}}`
        : ''
    }]}}}`,
  };

  const [selectedItem, setSelectedItem] = useState<
    CustomSearchRuleAlert | MlAlert | NoticeAlert | SuricataAlert
  >();

  const {
    loading: alertsLoading,
    error: alertsError,
    data: alertsData,
    refetch: alertsRefetch,
  } = useQuery<IQueryAlertsPaginated>(query, {
    skip:
      !detectionData ||
      !detectionData?.tenant_info?.tenant_id ||
      !detectionData?.alert_entity?.entity_id,
    variables: noFilterVariables,
    onCompleted: (data) => {
      alertsGetPageCount(data?.queryAlertsPaginated);
      setSelectedItem(
        (data?.queryAlertsPaginated?.alerts?.[0] as
          | CustomSearchRuleAlert
          | MlAlert
          | NoticeAlert
          | SuricataAlert) ?? {},
      );
    },
  });

  useEffect(() => {
    alertsGetPageCount(alertsData?.queryAlertsPaginated);
  }, [alertsData?.queryAlertsPaginated]);

  const handleColFilters = (filters: DetectionAlertsFilters) => {
    setFilters(filters ?? null);
    if (filters.length) {
      alertsRefetch({
        ...noFilterVariables,
        offset: 0,
        query: `{"query": {"bool": {"must": [{"term": {"alert_info.content_id": "${
          detectionData?.alert_info?.content_id
        }"}}, {"term": {"alert_entity.entity_id": "${
          detectionData?.alert_entity?.entity_id
        }"}}${filters?.length > 0 ? ',' : ''}${filters
          .map((filter) =>
            filter.id === 'source_entities'
              ? `{"nested": {
                "path": "related_alert_entities",
                "query": {
                  "bool": {
                    "must": [
                      {"term": {"related_alert_entities.entity_category": {"value": "source"}}},
                      {"match_phrase": {"related_alert_entities.entity_name": {"query": "${filter.value}", "analyzer": "keyword"}}}
                    ]
                  }
                }
              }}`
              : filter.id === 'destination_entities'
                ? `{"nested": {
                "path": "related_alert_entities",
                "query": {
                  "bool": {
                    "must": [
                      {"term": {"related_alert_entities.entity_category": {"value": "destination"}}},
                      {"match_phrase": {"related_alert_entities.entity_name": {"query": "${filter.value}", "analyzer": "keyword"}}}
                    ]
                  }
                }
              }}`
                : '',
          )
          .join(',')},{"range": {"alert_timestamp.end": {"gte": ${
          detectionData?.earliest_start_timestamp
        }}}}, {"range": {"alert_timestamp.start": {"lte": ${
          detectionData?.latest_start_timestamp
        }}}}${detectionData?.tenant_info?.tenant_id ? ',' : ''}${
          detectionData?.tenant_info?.tenant_id
            ? `{"terms":{"tenant":["${detectionData?.tenant_info?.tenant_id}"]}}`
            : ''
        }]}}}`,
      });
    } else {
      alertsRefetch({
        ...noFilterVariables,
      });
    }
  };

  const handleFetchRelatedAlerts = useCallback(
    (pageIndex: number, pageSize: number, sortBy: SortingRule<Alert>[]) => {
      setAlertsSize(pageSize);
      setAlertsOffset(pageIndex * pageSize);
      onFetchAlertsData(pageIndex, pageSize, sortBy);
    },
    [],
  );

  const widgetType = logscale_query ? 'table-view' : 'list-view';

  const query_string =
    metadataData?.getAlertMetadata?.metadata[0]?.custom_search_rule
      ?.query_string;

  const queryStringSearchBased = `?query=${
    logscale_query
      ? logscale_query && encodeURIComponent(logscale_query)
      : `${query_string && encodeURIComponent(query_string)}`
  }&live=false&humioStart=${
    earliest_start_timestamp === latest_start_timestamp
      ? (earliest_start_timestamp - 60) * 1000
      : earliest_start_timestamp * 1000
  }&humioEnd=${
    latest_start_timestamp === earliest_start_timestamp
      ? (latest_start_timestamp + 60) * 1000
      : latest_start_timestamp * 1000
  }&fullscreen=false&widgetType=${widgetType}&newestAtBottom=true&showOnlyFirstLine=false&start=${start}&end=${end}`;

  const totalItems = alertsData?.queryAlertsPaginated?.total_items;

  const alertType =
    alertsData?.queryAlertsPaginated?.alerts[0]?.alert_info?.alert_type;

  const alertsTabDescription =
    totalItems === 1
      ? i18n.formatString(
          alertActivity,
          String(totalItems),
          alert_entity?.entity_name,
          alert_info?.alert_name,
          moment.unix(latest_start_timestamp).format('MMMM Do, h:mma'),
        )
      : i18n.formatString(
          alertsActivity,
          String(totalItems),
          alert_entity?.entity_name,
          alert_info?.alert_name,
          moment.unix(earliest_start_timestamp).format('MMMM Do, h:mma'),
          moment.unix(latest_start_timestamp).format('MMMM Do, h:mma'),
        );

  const [activeAlert, setActiveAlert] = useState<
    | null
    | Alert
    | SanitizedMlAlert
    | SanitizedNoticeAlert
    | SanitizedSuricataAlert
    | SanitizedSearchBasedAlert
  >(null);

  const alerts = alertsData?.queryAlertsPaginated?.alerts;

  const sanitizedNoticeAlerts: Partial<NoticeAlert | Detection>[] = (
    alerts as NoticeAlert[]
  )?.map(
    ({
      uid,
      msg,
      alert_info,
      alert_entity,
      source_entities,
      destination_entities,
      alert_timestamp,
      tenant,
    }) => {
      return {
        uid,
        msg,
        alert_info,
        alert_entity,
        logscale_query,
        alert_timestamp,
        source_entities,
        destination_entities,
        timestamp: alert_timestamp?.observed,
        tenant,
      };
    },
  );

  const sanitizedSuricataAlerts: Partial<SuricataAlert | Detection>[] = (
    alerts as SuricataAlert[]
  )?.map(
    ({
      uid,
      alert_info,
      alert_entity,
      community_id,
      destination_ip,
      source_entities,
      destination_entities,
      alert_timestamp,
      tenant,
    }) => {
      return {
        uid,
        alert_info,
        alert_entity,
        logscale_query,
        alert_timestamp,
        community_id,
        destination_ip,
        source_entities,
        destination_entities,
        timestamp: alert_timestamp?.observed,
        tenant,
      };
    },
  );

  const sanitizedMlAlerts: Partial<MlAlert | Detection>[] = (
    alerts as MlAlert[]
  )?.map(
    ({
      models,
      pipeline,
      alert_info,
      alert_entity,
      alert_timestamp,
      source_entities,
      related_entities,
      destination_entities,
      average_technique_score_map,
    }) => {
      return {
        models,
        pipeline,
        alert_info,
        alert_entity,
        logscale_query,
        alert_timestamp,
        related_entities,
        average_technique_score_map,
        source_entities,
        destination_entities,
        timestamp: alert_timestamp?.observed,
      };
    },
  );

  const sanitizedSearchBasedAlerts: Partial<CustomSearchRuleAlert>[] = (
    alerts as CustomSearchRuleAlert[]
  )?.map(
    ({
      alert_info,
      alert_entity,
      alert_timestamp,
      source_entities,
      destination_entities,
    }) => {
      return {
        alert_info,
        alert_entity,
        logscale_query,
        alert_timestamp,
        source_entities,
        destination_entities,
        timestamp: alert_timestamp?.observed,
      };
    },
  );

  const detectionSummary = useMemo(() => {
    const {
      tenant_info,
      detection_keys,
      latest_start_timestamp: last_seen,
      detection_count,
      alert_info,
      alert_entity,
      first_seen,
      severity,
      total_detections,
    } = detectionData;

    const { entity_id, entity_name, entity_type, entity_category } =
      alert_entity || {};

    const { tenant_entity, tenant_detection } = detection_keys || {};

    const { alert_name, alert_type, content_id } = alert_info;

    const detectionSummary = {
      alert_name,
      alert_type,
      content_id,
      last_seen,
      entity_id,
      entity_name,
      entity_type,
      entity_category,
      tenant_info,
      tenant_entity,
      tenant_detection,
      detection_count,
      first_seen,
      severity,
      total_detections,
    };

    return detectionSummary;
  }, [detectionData]);

  const mlDataTableColumns: TableColumn<SanitizedMlAlert>[] = useMemo(
    () => [
      {
        Header: 'Timestamp',
        accessor: 'timestamp',
        Cell: (props) => {
          return <TimestampCell timestamp={props.value} isFromNow={false} />;
        },
      },
      {
        Header: 'Source',
        accessor: 'source_entities',
        disableSortBy: true,
        Filter: ColumnFilterPopover,
        Cell: (props) => (
          <AlertEntityPopover
            entities={props?.value}
            detectionSummary={detectionSummary}
          />
        ),
      },
      {
        Header: 'Destination',
        accessor: 'destination_entities',
        disableSortBy: true,
        Filter: ColumnFilterPopover,
        Cell: (props) => (
          <AlertEntityPopover
            entities={props?.value}
            detectionSummary={detectionSummary}
          />
        ),
      },
      {
        Header: 'Actions',
        accessor: 'logscale_query',
        disableSortBy: true,
        Cell: (props) => {
          const {
            models,
            pipeline,
            alert_info,
            alert_entity,
            alert_timestamp,
            related_entities,
            average_technique_score_map,
          } = props.row.original;
          return (
            <MLButtonsCol
              item={{
                models,
                pipeline,
                alert_info,
                alert_entity,
                alert_timestamp,
                related_entities,
                average_technique_score_map,
              }}
              logScaleQuery={props.value}
            />
          );
        },
      },
    ],
    [detectionSummary],
  );

  const searchBasedDataTableColumns: TableColumn<SanitizedSearchBasedAlert>[] =
    useMemo(
      () => [
        {
          Header: 'Timestamp',
          accessor: 'timestamp',
          Cell: (props) => {
            return <TimestampCell timestamp={props.value} isFromNow={false} />;
          },
        },
        {
          Header: 'Source',
          accessor: 'source_entities',
          disableSortBy: true,
          Filter: ColumnFilterPopover,
          Cell: (props) => (
            <AlertEntityPopover
              entities={props?.value}
              detectionSummary={detectionSummary}
            />
          ),
        },
        {
          Header: 'Destination',
          accessor: 'destination_entities',
          disableSortBy: true,
          Filter: ColumnFilterPopover,
          Cell: (props) => (
            <AlertEntityPopover
              entities={props?.value}
              detectionSummary={detectionSummary}
            />
          ),
        },
      ],
      [detectionSummary],
    );

  const noticeDataTableColumns: TableColumn<SanitizedNoticeAlert>[] = useMemo(
    () => [
      {
        Header: 'Timestamp',
        accessor: 'timestamp',
        Cell: (props) => {
          return (
            props?.value && (
              <TimestampCell timestamp={props.value} isFromNow={false} />
            )
          );
        },
      },
      {
        Header: 'Source',
        accessor: 'source_entities',
        disableSortBy: true,
        Filter: ColumnFilterPopover,
        Cell: (props) => (
          <AlertEntityPopover
            entities={props?.value}
            detectionSummary={detectionSummary}
          />
        ),
      },
      {
        Header: 'Destination',
        accessor: 'destination_entities',
        disableSortBy: true,
        Filter: ColumnFilterPopover,
        Cell: (props) => (
          <AlertEntityPopover
            entities={props?.value}
            detectionSummary={detectionSummary}
          />
        ),
      },
      {
        Header: 'Actions',
        accessor: 'logscale_query',
        disableSortBy: true,
        Cell: (props) => {
          const {
            msg,
            uid,
            alert_info,
            alert_entity,
            alert_timestamp,
            tenant,
          } = props.row.original;

          return (
            <NoticeButtonsCol
              item={
                {
                  uid,
                  msg,
                  alert_info,
                  alert_entity,
                  alert_timestamp,
                  tenant,
                } as NoticeAlert
              }
              logScaleQuery={props.value}
            />
          );
        },
      },
    ],
    [detectionSummary],
  );

  const suricataDataTableColumns: TableColumn<SanitizedSuricataAlert>[] =
    useMemo(
      () => [
        {
          Header: 'Timestamp',
          accessor: 'timestamp',
          Cell: (props) => {
            return (
              props?.value && (
                <TimestampCell timestamp={props.value} isFromNow={false} />
              )
            );
          },
        },
        {
          Header: 'Source',
          accessor: 'source_entities',
          disableSortBy: true,
          Filter: ColumnFilterPopover,
          Cell: (props) => (
            <AlertEntityPopover
              entities={props?.value}
              detectionSummary={detectionSummary}
            />
          ),
        },
        {
          Header: 'Destination',
          accessor: 'destination_entities',
          disableSortBy: true,
          Filter: ColumnFilterPopover,
          Cell: (props) => (
            <AlertEntityPopover
              entities={props?.value}
              detectionSummary={detectionSummary}
            />
          ),
        },
        {
          Header: 'Actions',
          accessor: 'logscale_query',
          disableSortBy: true,
          Cell: (props) => {
            const {
              uid,
              alert_info,
              alert_entity,
              community_id,
              destination_ip,
              alert_timestamp,
              tenant,
            } = props.row.original;

            return (
              <SuricataButtonsCol
                item={
                  {
                    uid,
                    alert_info,
                    alert_entity,
                    community_id,
                    destination_ip,
                    alert_timestamp,
                    tenant,
                  } as SuricataAlert
                }
                logScaleQuery={props.value}
              />
            );
          },
        },
      ],
      [detectionSummary],
    );

  useEffect(() => {
    setSlideDrawerOnClose?.(() => () => {
      setActiveAlert(null);
    });
  }, [setSlideDrawerOnClose, setActiveAlert]);

  if (
    (detectionData?.alert_info?.alert_type === 'ml' && !sanitizedMlAlerts) ||
    (detectionData?.alert_info?.alert_type === 'notice' &&
      !sanitizedNoticeAlerts) ||
    (detectionData?.alert_info?.alert_type === 'custom_search_rule' &&
      !sanitizedSearchBasedAlerts) ||
    (detectionData?.alert_info?.alert_type === 'suricata_corelight' &&
      !sanitizedSuricataAlerts)
  ) {
    return (
      <Panel>
        <GraphQLReqStatus loading={true} />
      </Panel>
    );
  }

  return alertsError ? (
    <Panel>
      <GraphQLReqStatus
        loading={false}
        isBackground={true}
        error={alertsError}
      />
    </Panel>
  ) : (
    <Panel>
      <FlushPanelContent>
        <VStack
          w="100%"
          spacing={0}
          alignItems="start"
          data-testid="detection-alerts"
        >
          <Flex
            px={4}
            py={5}
            w="100%"
            bg="layer.2"
            alignItems="center"
            justifyContent="space-between"
            borderBottomWidth="1px"
            borderBottomColor="white.300"
          >
            {!!alertsData?.queryAlertsPaginated?.total_items && (
              <Text
                data-testid={`${alertType}-alerts-tab-description`}
                textStyle="b1"
              >
                {alertsTabDescription}
              </Text>
            )}
            {detectionData?.alert_info?.alert_type === 'custom_search_rule' && (
              <Button
                size="sm"
                minWidth="auto"
                as={RouterLink}
                variant="solid"
                disabled={metadataLoading}
                data-testid="investigate-logs-button"
                leftIcon={
                  metadataLoading ? (
                    <LoadingSpinner size="sm" />
                  ) : (
                    <MuiIcon>frame_inspect</MuiIcon>
                  )
                }
                to={`${logSearch}${queryStringSearchBased}`}
              >
                {
                  i18n.pages.alertDetails.panels.entityDetails.buttons
                    .investigateLogs
                }
              </Button>
            )}
          </Flex>
          <Box width="100%">
            <Panel border={0}>
              <FlushPanelContent>
                {detectionData?.alert_info?.alert_type && (
                  <DataTable
                    isMemod={false}
                    hideColumnHeaders={alertsLoading}
                    isPaginable={Boolean(
                      !alertsLoading && !alertsError && totalItems,
                    )}
                    isFilterManual={true}
                    onColumnFilter={handleColFilters}
                    autoResetPage={alertsOffset / alertsSize >= alertsPageCount}
                    // @ts-ignore
                    pageSize={alertsPageSize}
                    pageCount={alertsPageCount}
                    // autoResetPage={false}
                    autoResetSortBy={false}
                    autoResetRowSelection={false}
                    isPaginationManual={true}
                    // @ts-ignore
                    columns={
                      detectionData?.alert_info?.alert_type === 'ml'
                        ? mlDataTableColumns
                        : detectionData?.alert_info?.alert_type === 'notice'
                          ? noticeDataTableColumns
                          : detectionData?.alert_info?.alert_type ===
                              'suricata_corelight'
                            ? suricataDataTableColumns
                            : detectionData?.alert_info?.alert_type ===
                                'custom_search_rule'
                              ? searchBasedDataTableColumns
                              : []
                    }
                    onFetchData={handleFetchRelatedAlerts}
                    itemCount={
                      alertsData?.queryAlertsPaginated?.total_items || 0
                    }
                    emptyBodyContent={
                      <Center m={4}>{controls.noResults}</Center>
                    }
                    // @ts-ignore
                    data={
                      detectionData?.alert_info?.alert_type === 'ml'
                        ? (sanitizedMlAlerts as SanitizedMlAlert[])
                        : detectionData?.alert_info?.alert_type === 'notice'
                          ? (sanitizedNoticeAlerts as SanitizedNoticeAlert[])
                          : detectionData?.alert_info?.alert_type ===
                              'suricata_corelight'
                            ? (sanitizedSuricataAlerts as SanitizedSuricataAlert[])
                            : detectionData?.alert_info?.alert_type ===
                                'custom_search_rule'
                              ? (sanitizedSearchBasedAlerts as SanitizedSearchBasedAlert[])
                              : []
                    }
                    initialState={{
                      pageIndex: alertsPageIndex,
                      sortBy: alertsSortBy,
                      // pageSize: alertsPageSize,
                    }}
                    onRowClick={({ original }, event) => {
                      if (
                        (event.target as HTMLElement)?.className?.includes &&
                        !['icon', 'link', 'menu', 'button', 'switch'].some(
                          (classNameString) =>
                            (event.target as HTMLElement).className.includes(
                              classNameString,
                            ),
                        )
                      ) {
                        setPortalType?.('alert');
                        setIsSlideDrawerOpen?.(true);
                        setActiveAlert(original);
                      }
                    }}
                    footer={DetectionAlertsFooter({
                      loading: alertsLoading,
                      error: alertsError,
                      data: alertsData?.queryAlertsPaginated?.alerts,
                    })}
                  />
                )}
              </FlushPanelContent>
            </Panel>
          </Box>
        </VStack>
      </FlushPanelContent>
      {portalType === 'alert' &&
        slideDrawerTitle &&
        createPortal(
          <Box __css={{ wordWrap: 'anywhere' }}>
            <Box
              __css={{ wordWrap: 'anywhere' }}
            >{`${activeAlert?.alert_info.alert_name} | ${moment
              .unix(activeAlert?.alert_timestamp?.observed)
              .format()}`}</Box>
            <Flex pt={3} gap={2}>
              <HStack>
                {activeAlert?.alert_info.alert_type === 'ml' ? (
                  <MLButtonsCol
                    item={activeAlert as SanitizedMlAlert}
                    showContent={true}
                    logScaleQuery={logscale_query}
                  />
                ) : activeAlert?.alert_info.alert_type === 'notice' ? (
                  <NoticeButtonsCol
                    item={activeAlert as SanitizedNoticeAlert}
                    showContent={true}
                    logScaleQuery={logscale_query}
                  />
                ) : activeAlert?.alert_info.alert_type ===
                  'suricata_corelight' ? (
                  <SuricataButtonsCol
                    item={activeAlert as SanitizedSuricataAlert}
                    showContent={true}
                    logScaleQuery={logscale_query}
                  />
                ) : null}
              </HStack>
              {downloadUrl && (
                <Button
                  variant="solid"
                  colorScheme="gray"
                  as="a"
                  target="_blank"
                  href={downloadUrl}
                  download="PCAP"
                  aria-label={controls.downloadPcap}
                  leftIcon={<MuiIcon>download</MuiIcon>}
                >
                  {controls.downloadPcap}
                  <Popover trigger="hover" placement="top">
                    <PopoverTrigger>
                      <MuiIcon color="text.primary" marginLeft="1">
                        info
                      </MuiIcon>
                    </PopoverTrigger>
                    <Portal>
                      <PopoverContent
                        w="200px"
                        backgroundColor="gray.600"
                        padding="1"
                        margin="1"
                      >
                        {controls.downloadPcapTooltipText}
                        <PopoverArrow backgroundColor="gray.600" />
                      </PopoverContent>
                    </Portal>
                  </Popover>
                </Button>
              )}
            </Flex>
          </Box>,
          slideDrawerTitle,
        )}
      {portalType === 'alert' &&
        slideDrawerBody &&
        createPortal(
          activeAlert?.alert_info.alert_type === 'ml' ? (
            <AlertRowSplitML
              item={activeAlert as SanitizedMlAlert}
              loading={alertsLoading}
              logScaleQuery={logscale_query}
            />
          ) : activeAlert?.alert_info.alert_type === 'notice' ? (
            <AlertRowSplitNotice
              item={activeAlert as SanitizedNoticeAlert}
              loading={alertsLoading}
              setDownloadUrl={setDownloadUrl}
              getAppConfigurationsData={getAppConfigurationsData}
              detectionData={detectionData}
              isGPTPrivateDataEnabled={isGPTPrivateDataEnabled}
              logScaleQuery={logscale_query}
            />
          ) : activeAlert?.alert_info.alert_type === 'suricata_corelight' ? (
            <AlertRowSplitSuricata
              item={activeAlert as SanitizedSuricataAlert}
              getAppConfigurationsData={getAppConfigurationsData}
              loading={alertsLoading}
              suricataRuleText={suricataRuleText}
              setDownloadUrl={setDownloadUrl}
              isGPTPrivateDataEnabled={isGPTPrivateDataEnabled}
              detectionData={detectionData}
              logScaleQuery={logscale_query}
            />
          ) : activeAlert?.alert_info.alert_type === 'custom_search_rule' ? (
            <AlertRowSplitSearchBased
              item={activeAlert as SanitizedSearchBasedAlert}
              loading={alertsLoading}
            />
          ) : null,
          slideDrawerBody,
        )}
    </Panel>
  );
};
