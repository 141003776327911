import {
  EntityInformationContext,
  EntityInstance,
} from '@gamma/investigator/context';
import { Dispatch, SetStateAction, useContext, useEffect } from 'react';

export const useActiveEntityStates = () => {
  const { activeEntity, setActiveEntity } = useContext(
    EntityInformationContext,
  );

  const getExcludedEntitiesVariables = ({
    content_id,
    entity_name,
    entity_type,
    tenant_name,
  }: {
    content_id: string;
    entity_name: string;
    entity_type: string;
    tenant_name: string;
  }) => {
    return {
      query:
        entity_type === 'DOMAIN'
          ? `{"query": {"bool": {"must": [{"match": {"content_id": "${
              content_id
            }"}}, {"term": {"entity_name.keyword": "${entity_name}"}}, {"term": {"entity_type": "${entity_type}"}}${
              tenant_name ? ',' : ''
            }${tenant_name ? `{"terms":{"tenant":["${tenant_name}"]}}` : ''}]}}}`
          : `{"query": {"bool": {"must": [{"match": {"content_id": "${
              content_id
            }"}}, {"term": {"entity_type": "${entity_type}"}}, {"bool": {"should": [{"term": {"entity_ip": "${entity_name}"}}, {"range": {"entity_ip_range": {"from": "${entity_name}", "to": "${entity_name}"}}}]}}${
              tenant_name ? ',' : ''
            }${tenant_name ? `{"terms":{"tenant":["${tenant_name}"]}}` : ''}]}}}`,
      offset: 0,
      size: 10,
      sort: [
        {
          sort_by: 'entity_name.keyword',
          sort_dir: 'desc',
        },
      ],
    };
  };

  const updateEntityState = (
    entity: EntityInstance,
    setEntityState: Dispatch<SetStateAction<EntityInstance | undefined>>,
  ) => {
    const { edrPayload, entityEdrStatus, edrConfigData } = entity || {};

    setEntityState?.((prevState) => ({
      ...prevState,
      showIsolateEntity:
        !!edrPayload?.entity_id &&
        entityEdrStatus !== undefined &&
        edrConfigData?.enabled &&
        edrConfigData?.edr_action_enabled,
      excludedEntitiesVariables:
        entity?.detectionData?.content_id &&
        entity?.tenantInfo?.tenant_id &&
        entity?.entityName &&
        entity?.entityType
          ? getExcludedEntitiesVariables({
              content_id: entity.detectionData.content_id,
              tenant_name: entity.tenantInfo?.tenant_id,
              entity_name: entity.entityName,
              entity_type: entity.entityType,
            })
          : undefined,
    }));
  };

  useEffect(() => {
    if (activeEntity && setActiveEntity) {
      updateEntityState(activeEntity, setActiveEntity);
    }
  }, [
    activeEntity?.entityName,
    activeEntity?.entityType,
    activeEntity?.tenantInfo,
    activeEntity?.detectionData,
    activeEntity?.edrPayload,
    activeEntity?.edrConfigData,
    activeEntity?.entityEdrStatus,
    activeEntity?.entityEdrStatus?.entity_edr_status,
  ]);
};
