import {
  AuthContext,
  EntityInformationContext,
} from '@gamma/investigator/context';
import { useDateRangeQueryString } from '@gamma/investigator/hooks';
import {
  EntityDetectionTimelineItem,
  useLazyGetEntityDetectionTimelinePaginated,
} from '@gamma/investigator/queries';
import { useContext } from 'react';
import { useLazyGetEdrData } from '../useLazyGetEdrData';
import { useQueryExcludedEntities } from '../useQueryExcludedEntities';

interface useGetEntityInformationProps {
  entityId: string;
  tenantId: string;
}

export const useGetEntityInformation = ({
  entityId,
  tenantId,
}: useGetEntityInformationProps) => {
  const { userLicense } = useContext(AuthContext);
  const { activeEntity, setActiveEntity } = useContext(
    EntityInformationContext,
  );

  const { start, end } = useDateRangeQueryString();

  const [
    getEntityDetectionTimelinePaginated,
    {
      loading: getEntityDetectionTimelinePaginatedLoading,
      error: getEntityDetectionTimelinePaginatedError,
      // data: getEntityDetectionTimelinePaginatedData,
    },
  ] = useLazyGetEntityDetectionTimelinePaginated({
    variables: {
      size: 3,
      offset: 0,
      start,
      end,
      tenant_id: tenantId,
      entity_id: entityId,
      must_conds: [
        `{"terms": {"tenant": ["${tenantId || userLicense?.tenant}"]}}`,
        '{"term": {"detection_status": "open"}}',
      ],
    },
    onCompleted: ({ getEntityDetectionTimelinePaginated }) => {
      const { total_items } = getEntityDetectionTimelinePaginated;
      const {
        alert_name,
        alert_type,
        content_id,
        entity_id,
        entity_name,
        entity_type,
        tenant_entity,
        first_seen,
        last_seen,
        severity,
        tenant_info,
        tenant_detection: detection_id,
        total_detections,
      } = getEntityDetectionTimelinePaginated?.data?.[0] || {};
      const detectionData = {
        alert_name,
        alert_type,
        content_id,
        entity_id,
        entity_name,
        entity_type,
        tenant_entity,
        detection_id,
        total_items,
        first_seen,
        last_seen,
        severity,
        tenant_info,
        total_detections,
        detection_count: total_detections,
      };
      const detectionDataList = getEntityDetectionTimelinePaginated?.data?.map(
        (item: EntityDetectionTimelineItem) => {
          return {
            alert_name: item?.alert_name,
            severity: item?.severity,
            is_custom_severity: item?.is_custom_severity,
          };
        },
      );
      setActiveEntity?.((prevState) => ({
        ...prevState,
        detectionData,
        detectionDataList,
      }));
    },
  });

  const { loading: getEdrDataLoading, getEDRConfiguration } = useLazyGetEdrData(
    {
      tenant: tenantId || userLicense?.tenant,
      activeEntity,
      setActiveEntity,
    },
  );

  const { loading: queryAlertMetadataExcludedEntitiesPaginatedLoading } =
    useQueryExcludedEntities({
      setActiveEntity,
      variables: activeEntity?.excludedEntitiesVariables,
    });

  const getEdrData = async () => {
    await getEDRConfiguration();
  };

  return {
    getEdrData,
    getEdrDataLoading,
    getEntityDetectionTimelinePaginated,
    getEntityDetectionTimelinePaginatedError,
    getEntityDetectionTimelinePaginatedLoading,
    queryAlertMetadataExcludedEntitiesPaginatedLoading,
  };
};
